import { Box, type SxProps, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingScreen } from '@components/LoadingScreen';
import {
  useImageSource,
  type UseImageSourceProps,
  type ImageSourceUrl,
} from '@hooks/useImageSource';

export type ImageProps = UseImageSourceProps & {
  alt?: string;
  sx?: SxProps;
  sxLoading?: SxProps;
};

export const Image = forwardRef((props: ImageProps, ref) => {
  const {
    id,
    alt = 'alt text',
    sx,
    sxLoading,
    options: optionsProp,
    extension,
    ...optionsDirectProp
  } = props;

  const imageSource = useImageSource({
    id,
    extension,
    options: optionsProp,
    ...optionsDirectProp,
  });

  return (
    <ImageWithSource alt={alt} imageSource={imageSource} ref={ref} sx={sx} sxLoading={sxLoading} />
  );
});

Image.displayName = 'Image';

type ImageWithSourceProps = Omit<ImageProps, 'id'> & {
  imageSource: ImageSourceUrl;
};

export const ImageWithSource = forwardRef((props: ImageWithSourceProps, ref) => {
  const { alt = 'alt text', imageSource, sx, sxLoading } = props;
  const { t } = useTranslation();

  if (imageSource instanceof Error) {
    return (
      <Box component="div" ref={ref}>
        <Typography>{t('components.image.loadingError')}</Typography>
      </Box>
    );
  }

  if (!imageSource) {
    return (
      <Box component="div" ref={ref} sx={{ ...sxLoading }}>
        <LoadingScreen isDarkMode />
      </Box>
    );
  }

  return <Box alt={alt} component="img" ref={ref} src={imageSource} sx={sx} />;
});

ImageWithSource.displayName = 'ImageWithSource';
