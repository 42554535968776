import { GrpcRestFetchTransport, useAuth0Interceptor } from '@deepup/api-utils';
import { photo as photoApi, photoClient } from '@deepup/apis';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { getEnvironment } from '@utils/getEnvironment';

type EnabledPayload = {
  projectId: string;
};

const { apiUnified } = getEnvironment();

export const useListPhotos = (projectId: string | null) => {
  const authInterceptor = useAuth0Interceptor();
  const client = useMemo(() => {
    const transportRest = new GrpcRestFetchTransport({
      baseUrl: apiUnified,
      fetchInit: {
        credentials: 'include',
      },
      interceptors: [authInterceptor],
    });

    return new photoClient.PhotoServiceClient(transportRest);
  }, [authInterceptor]);

  const isEnabled = !!projectId;

  return useQuery<photoApi.ListPhotosResponse>({
    queryKey: ['listPhotos', projectId],
    queryFn: () => client.listPhotos({ projectId } as EnabledPayload).response,
    enabled: isEnabled,
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  });
};
