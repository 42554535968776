import { PreviewSearchField } from '@deepup/preview-search-field';
import { debounce } from '@mui/material';
import { LngLat } from 'mapbox-gl';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFilters } from '@hooks/useFilters';
import { useMapFocus } from '@hooks/useMapFocus';
import { getEnvironment } from '@utils/getEnvironment';
import { trackEvent } from '@utils/trackEvent';

type MapboxGeocoderResult = {
  text: string;
  place_name: string;
  center: [number, number];
};

export const GeocoderField = () => {
  const [searchResults, setSearchResults] = useState<MapboxGeocoderResult[]>([]);
  const { focusLocation } = useMapFocus();
  const { t, i18n } = useTranslation();
  const { setMarker } = useFilters();

  const fetchCall = async (searchTerm: string) => {
    if (searchTerm === '') {
      setSearchResults([]);
      setMarker(null);

      return;
    }
    const encodedSearchTerm = encodeURIComponent(searchTerm);

    const { language } = i18n;
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedSearchTerm}.json?language=${language}&access_token=${getEnvironment().mapboxAccessToken}`,
    );

    if (!response.ok) {
      return;
    }

    const data = (await response.json()) as { features: MapboxGeocoderResult[] };

    setSearchResults(data.features);
  };

  return (
    <PreviewSearchField
      onClear={() => setMarker(null)}
      onClickResult={({ primaryText, secondaryText }) => {
        const geocoderResult = searchResults.find(
          (result) => result.text === primaryText && result.place_name.includes(secondaryText),
        )!;

        const {
          center: [lng, lat],
        } = geocoderResult;

        if (!lat || !lng) return;

        const center = new LngLat(lng, lat);

        focusLocation({ center, zoom: 14, duration: 2000 });

        setMarker(center);

        trackEvent('mapGeocoderResult', { geocoder: geocoderResult });

        setSearchResults([]);
      }}
      onCloseResults={() => setSearchResults([])}
      onInput={debounce(fetchCall, 250)}
      placeholder={t('components.filterBox.fields.geocoderField.searchField.placeholder')}
      searchResults={searchResults.map((result) => ({
        primaryText: result.text,
        secondaryText: result.place_name.replace(result.text + ', ', ''),
      }))}
    />
  );
};
