import { type Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import type { FeatureCollection } from 'geojson';

import { FetchError } from '@utils/FetchError';
import { getEnvironment } from '@utils/getEnvironment';

export type FilterTrassMetadata = {
  featureId: string;
};

const get = async (auth: Auth0ContextInterface, { featureId }: FilterTrassMetadata) => {
  const { apiUnified } = getEnvironment();

  const token = await auth.getAccessTokenSilently();

  const response = await fetch(
    `${apiUnified}layer/v1?request=GetFeature&typeNames=deepup:trass_segmented&outputformat=JSON&cql_filter=fid='${featureId}'`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  const responseJson = await response.json();

  if (response.ok) {
    return responseJson;
  }

  if (!response.ok) {
    throw new FetchError(response, responseJson);
  }
};

export const useApiTrassMetadata = (filter: FilterTrassMetadata) => {
  const auth0 = useAuth0();

  return useQuery<FeatureCollection, FetchError | Error>({
    queryKey: ['useApiTrassMetadata', filter],
    queryFn: () => get(auth0, filter),
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  });
};
