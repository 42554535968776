import { Box, Button } from '@mui/material';
import type { EventData, MapboxEvent } from 'mapbox-gl';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFilters } from '@hooks/useFilters';
import { useMapEvent } from '@hooks/useMapEvent';
import { useThemeMode } from '@hooks/useThemeMode';
import { getEnvironment } from '@utils/getEnvironment';

export const MapStyleControl = ({ useStaticApi = false }: { useStaticApi?: boolean }) => {
  const { satelliteView, setSatelliteView } = useFilters();
  const { isDarkMode } = useThemeMode();
  const { t } = useTranslation();

  const [transformation, setTransformation] = useState<{
    lng: number;
    lat: number;
    zoom: number;
  } | null>(null);

  const onMapEvent = useCallback(
    (event: MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & EventData) => {
      const center = event.target.getCenter();
      const zoom = event.target.getZoom();

      setTransformation({ lng: center.lng, lat: center.lat, zoom });
    },
    [],
  );

  useMapEvent('moveend', onMapEvent);
  useMapEvent('load', onMapEvent);

  if (!transformation) {
    return null;
  }

  const accessToken = getEnvironment().mapboxAccessToken;

  const style = satelliteView ? (isDarkMode ? 'dark-v10' : 'streets-v10') : 'satellite-v9';
  const staticImgSrc = `https://api.mapbox.com/styles/v1/mapbox/${style}/static/${transformation.lng},${transformation.lat},${transformation.zoom}/58x58?access_token=${accessToken}&logo=false&attribution=false`;
  const imgSrc = satelliteView
    ? isDarkMode
      ? '/assets/map-style-dark.png'
      : '/assets/map-style-light.png'
    : '/assets/map-style-satellite.png';

  return (
    <Box
      component="div"
      sx={{
        position: 'absolute',
        right: '49px',
        bottom: '62px',
      }}
    >
      <Button
        onClick={() => setSatelliteView(!satelliteView)}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.divider}`,
          height: '58px',
          width: '58px',
          minWidth: '58px',
          fontSize: '10px',
          fontWeight: 'initial',
          color: 'white',
          textTransform: 'none',
          padding: '0',
          overflow: 'hidden',
        })}
      >
        <Box
          alt="map"
          component="img"
          src={useStaticApi ? staticImgSrc : imgSrc}
          sx={{ display: 'block' }}
        />
        <Box
          component="div"
          sx={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '56px',
            height: '26px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            background:
              'linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.5) 20%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%)',
            paddingBottom: '2px',
          }}
        >
          {!satelliteView
            ? t('components.map.mapStyleControl.satelliteView')
            : t('components.map.mapStyleControl.mapView')}
        </Box>
      </Button>
    </Box>
  );
};
