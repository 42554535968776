import { Typography } from '@mui/material';
import { bbox } from '@turf/bbox';
import { useEffect } from 'react';
import { Layer } from 'react-map-gl';

import { MapPopup } from '@components/MapPopup';
import { type VectorLayerProps } from '@components/MapSource/VectorLayerProps';
import { useFilters } from '@hooks/useFilters';
import { useMapFeatureState } from '@hooks/useMapFeatureState';
import { useMapFeatureZoom } from '@hooks/useMapFeatureZoom';
import { useMapFocus } from '@hooks/useMapFocus';
import { useMapHoverFeature } from '@hooks/useMapHoverFeature';
import { useMapSelectFeatures } from '@hooks/useMapSelectFeatures';
import {
  filterProjectsExpression,
  isHoveredExpression,
  projectCloseupThreshold,
} from '@utils/mapboxExpressions';

export const ProjectPolygons = ({ source, sourceLayer }: VectorLayerProps) => {
  const filters = useFilters();
  const filter = filterProjectsExpression(filters);

  // zoom into clicked polygon
  const { focusBounds } = useMapFocus();
  const { selectedFeature } = useMapSelectFeatures(['project-fill']);

  // function to determine, if you can even zoom into object or just out
  const { getFeatureZoom } = useMapFeatureZoom();

  useEffect(() => {
    selectedFeature &&
      !getFeatureZoom(selectedFeature).exceedsMapZoom &&
      focusBounds?.(selectedFeature.bbox ?? bbox(selectedFeature));
  }, [getFeatureZoom, selectedFeature, focusBounds]);

  // set hover cursor
  // because of a mapbox bug, you need to track both layers separately
  const { feature: hoveredFill } = useMapHoverFeature('project-fill');
  const { feature: hoveredOutline } = useMapHoverFeature('project-outline-closeup');

  const hoveredFeature = hoveredFill ?? hoveredOutline;

  // add hover state for highlighting hovered polygon
  useMapFeatureState(hoveredFeature, { hover: true });

  return (
    <>
      {hoveredFeature && (
        <>
          <MapPopup>
            <Typography sx={{ padding: '0 5px' }}>{hoveredFeature.properties?.['name']}</Typography>
          </MapPopup>
        </>
      )}
      <Layer
        filter={filter}
        id="project-fill"
        maxzoom={projectCloseupThreshold}
        paint={{
          'fill-color': '#00A887',
          'fill-opacity': 0.8,
        }}
        source={source}
        source-layer={sourceLayer}
        type="fill"
      />
      <Layer
        beforeId="project-fill"
        filter={filter}
        id="project-outline-hovered"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        maxzoom={projectCloseupThreshold}
        paint={{
          'line-color': '#006B56',
          'line-width': ['case', isHoveredExpression, 4, 0],
        }}
        source={source}
        source-layer={sourceLayer}
        type="line"
      />
      <Layer
        beforeId="project-outline-hovered"
        filter={filter}
        id="project-outline-closeup"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        minzoom={projectCloseupThreshold}
        paint={{
          'line-color': '#00A887',
          'line-width': 2,
        }}
        source={source}
        source-layer={sourceLayer}
        type="line"
      />
    </>
  );
};
