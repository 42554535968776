import { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';

import { useMapEvent } from '@hooks/useMapEvent';

export const useMapImage = (url: string) => {
  const { current: map } = useMap();
  const [imageID, setImageID] = useState<string | null>(null);
  const [image, setImage] = useState<HTMLImageElement | ImageBitmap>();

  // load image for url
  useEffect(() => {
    if (!map) return;
    map.loadImage(url, (_, image) => setImage(image));
  }, [url, map]);

  // when style changes, the new style might not know the image
  // so we need to check and re-add
  const callback = useCallback(() => {
    if (!map || !image || map.hasImage(url)) return;
    map.addImage(url, image);
    setImageID(url);
  }, [image, map, url]);

  // listen for style change
  useMapEvent('styledata', callback);

  return imageID;
};
