import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageZoom } from '@components/ImageZoom';
import { LoadingScreen } from '@components/LoadingScreen';
import { useApiTopDownView } from '@hooks/useApiTopDownView';
import { useThemeMode } from '@hooks/useThemeMode';
import { FetchError } from '@utils/FetchError';
import { trackEvent } from '@utils/trackEvent';

export type TopDownViewerProps = {
  scanId: string;
};

export const TopDownViewer = ({ scanId }: TopDownViewerProps) => {
  const { t } = useTranslation();
  const { data, error, isFetching } = useApiTopDownView(scanId);
  const { isDarkMode } = useThemeMode();

  // tracking
  useEffect(() => {
    if (!isFetching) {
      trackEvent('topDownViewerOpened', { scan_id: scanId, is_error: !!error });
    }
  }, [error, isFetching, data, scanId]);

  if (isFetching) {
    return <LoadingScreen isDarkMode={isDarkMode} />;
  }

  if (error) {
    if (!(error instanceof FetchError)) {
      console.error(error);
    }

    return (
      <Typography>
        {error instanceof FetchError
          ? error.error.message
          : t('components.topDownViewer.errors.unknown')}
      </Typography>
    );
  }

  if (!data || (data && !data.url)) {
    return <Typography>{t('components.topDownViewer.errors.no2dView')}</Typography>;
  }

  return <ImageZoom image={<img alt={`top-down view scan ${scanId}`} src={data.url} />} />;
};
