import turfBbox from '@turf/bbox';
import { featureCollection } from '@turf/helpers';
import type { Feature } from 'geojson';
import { useEffect } from 'react';

import { useMapFocus } from '@hooks/useMapFocus';

export type UseFeaturesFocusProps = {
  data?: { features: Feature[] | null };
  mapId?: string;
  initialViewState?: {
    zoom: number;
    latitude: number;
    longitude: number;
  };
};

export const useFeaturesFocus = ({ data, mapId, initialViewState }: UseFeaturesFocusProps) => {
  const { focusBounds, focusLocation } = useMapFocus(mapId);

  useEffect(() => {
    if (!data || !data.features?.length) {
      if (initialViewState) {
        focusLocation({
          center: [initialViewState.longitude, initialViewState.latitude],
          zoom: initialViewState.zoom,
        });
      }

      return;
    }

    try {
      const bbox = turfBbox(featureCollection(data.features));

      focusBounds(bbox, 30);
    } catch (error) {
      console.error('Could not focus', error);
    }
  }, [data, focusBounds, focusLocation, initialViewState]);
};
