export const getEnvironment = () => {
  return {
    mapboxAccessToken: import.meta.env?.VITE_MAPBOX_ACCESS_TOKEN,
    auth0Audience: import.meta.env?.VITE_AUTH0_AUDIENCE,
    auth0ClientId: import.meta.env?.VITE_AUTH0_CLIENT_ID,
    auth0Domain: import.meta.env?.VITE_AUTH0_DOMAIN,
    wmtsSource: import.meta.env?.VITE_WMTS_SOURCE,
    wfsSource: import.meta.env?.VITE_WFS_SOURCE,
    apiConMon: import.meta.env?.VITE_API_CONMON,
    apiTrenchviewer: import.meta.env?.VITE_API_TRENCHVIEWER,
    apiUnified: import.meta.env?.VITE_API_UNIFIED,
    cssUrl: import.meta.env?.VITE_CSS_URL,
    appVersion: import.meta.env?.VITE_APP_VERSION,
    flagsmithEnvId: import.meta.env?.VITE_FLAGSMITH_ENV_ID,
    flagSmithListeningInterval: import.meta.env?.VITE_FLAGSMITH_LISTENING_INTERVAL,
    sentryDsn: import.meta.env?.VITE_SENTRY_DSN,
    sentryAuthToken: import.meta.env?.VITE_SENTRY_AUTH_TOKEN,
    dev: import.meta.env?.DEV,
    isMockingEnabled: import.meta.env?.VITE_USE_MSW === 'true',
    mode: import.meta.env?.MODE,
  };
};
