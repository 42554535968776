import { CheckmarkCircleOutline, WarningFilled } from '@deepup/icons';
import { Stack, Typography } from '@mui/material';
import type { MapboxGeoJSONFeature } from 'mapbox-gl';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, useMap } from 'react-map-gl';
import { useParams } from 'react-router-dom';

import { MapPopup } from '@components/MapPopup';
import { type VectorLayerProps } from '@components/MapSource/VectorLayerProps';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useFilters } from '@hooks/useFilters';
import { useMapFeatureState } from '@hooks/useMapFeatureState';
import { useMapFeatures } from '@hooks/useMapFeatures';
import { useMapHoverFeature } from '@hooks/useMapHoverFeature';
import { useMapSelectFeatures } from '@hooks/useMapSelectFeatures';
import { useMapZoomIsAbove } from '@hooks/useMapZoomIsAbove';
import { useNavigateExtended } from '@hooks/useNavigateExtended';
import {
  filterPrelabelExpression,
  isBelowDepth,
  isBelowLowestDepth,
  isHoveredExpression,
  isScanExpression,
  projectCloseupThreshold,
} from '@utils/mapboxExpressions';

const retrieveSegmentDepth = ({ properties }: MapboxGeoJSONFeature) =>
  properties && properties.depth && !isNaN(properties.depth)
    ? Math.max(Number(properties.depth), 0)
    : null;

const retrieveLowestDepth = ({ state }: MapboxGeoJSONFeature) =>
  state.lowestDepth && !isNaN(state.lowestDepth) ? Math.max(Number(state.lowestDepth), 0) : null;

export const Prelabels = ({ source, sourceLayer }: VectorLayerProps) => {
  const { t } = useTranslation();
  const { scanId, elementType } = useParams();

  const { prelabelZoomThreshold: lodZoomThreshold, isPrelabelsEnabled } = useFeatureFlags();
  const { isZoomAboveThreshold: isNear } = useMapZoomIsAbove(lodZoomThreshold);

  const { feature: hoveredFeatureNear } = useMapHoverFeature('prelabels');
  const { feature: hoveredFeatureFar } = useMapHoverFeature('prelabels-far');
  const hoveredFeature = isNear ? hoveredFeatureNear : hoveredFeatureFar;

  // check if prelabel is not deep enough, compared to `minDepth`
  const retrieveDepth = isNear ? retrieveSegmentDepth : retrieveLowestDepth;
  const hoveredDepth = hoveredFeature && retrieveDepth(hoveredFeature);
  const { minPrelabelDepth: minDepth, showPrelabels } = useFilters();
  const isNotDeepEnough =
    typeof minDepth === 'number' && typeof hoveredDepth === 'number' && hoveredDepth < minDepth;

  // go to next
  const { selectedFeature } = useMapSelectFeatures(['prelabels', 'prelabels-far']);
  const navigate = useNavigateExtended();

  useEffect(() => {
    const scanId = selectedFeature?.properties?.scan_id;

    if (scanId) {
      navigate(`scan/${scanId}/prelabel/top-down-view`, {
        preserveSearch: true,
        preserveHash: true,
      });
    }
    // https://github.com/remix-run/react-router/issues/7634
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);

  // here, all features are mapped to their lowest depth
  // the depth is then set as a feature state, so mapbox expressions can use them
  const features = useMapFeatures({ source: source, sourceLayer });
  const { current: map } = useMap();

  useEffect(() => {
    if (!map || features.length === 0) return;

    const lowestDepthPerFeature = features
      .filter(({ id, properties }) => typeof id === 'string' && !isNaN(properties?.depth))
      .map(({ id, properties }) => ({ id: id as string, depth: Number(properties?.depth) }))
      .reduce(
        (acc, item) => {
          // store depth into dict, when it's not present or lower than what's currently stored
          if (!acc[item.id] || item.depth < acc[item.id]) {
            acc[item.id] = item.depth;
          }

          return acc;
        },
        {} as { [key: string]: number },
      );

    // set lowest depth as feature state
    Object.keys(lowestDepthPerFeature)
      .map((id) => ({ id, source: source, sourceLayer }))
      .filter((feature) => {
        const current = map.getFeatureState(feature)?.lowestDepth;

        // only update if needed! we have to filter here to prevent an update cycle
        return typeof current !== 'number' || current > lowestDepthPerFeature[feature.id];
      })
      .forEach((feature) =>
        map.setFeatureState(feature, { lowestDepth: lowestDepthPerFeature[feature.id] }),
      );
  }, [features, map, sourceLayer, source]);

  const filters = useFilters();

  const filter = filterPrelabelExpression(filters);

  useMapFeatureState(hoveredFeatureNear, { hover: true });
  useMapFeatureState(hoveredFeatureFar, { hover: true });

  const isPrelabelExpression = isScanExpression(scanId, elementType, 'prelabel');

  return (
    <>
      {hoveredFeature && typeof hoveredDepth === 'number' && (
        <MapPopup>
          <Stack alignItems="center" direction="row" spacing="5px" sx={{ padding: '8px' }}>
            {isNotDeepEnough ? (
              <WarningFilled color="#ef4447" height="20px" width="20px" />
            ) : (
              <CheckmarkCircleOutline height="20px" width="20px" />
            )}
            <Typography fontWeight="14px" lineHeight="20px">
              {!isNear ? `${t('components.mapSource.prelabels.lowest')} ` : ''}
              {t('components.mapSource.prelabels.depth')}: {hoveredDepth}cm
            </Typography>
          </Stack>
        </MapPopup>
      )}
      {isPrelabelsEnabled && showPrelabels && (
        <>
          <Layer
            filter={filter}
            id="prelabels"
            layout={{
              'line-sort-key': ['case', isBelowDepth(minDepth ?? -1000), 2, 1],
              'line-join': 'round',
              'line-cap': 'round',
            }}
            minzoom={lodZoomThreshold}
            paint={{
              'line-color': [
                'case',
                isBelowDepth(minDepth ?? -1000),
                '#ef4447',
                ['case', isPrelabelExpression, '#005C49', '#00A887'],
              ],
              'line-width': ['case', isHoveredExpression, 12, 10],
            }}
            source={source}
            source-layer={sourceLayer}
            type="line"
          />
          <Layer
            filter={filter}
            id="prelabels-far"
            layout={{
              'line-sort-key': 1,
              'line-join': 'round',
              'line-cap': 'round',
            }}
            maxzoom={lodZoomThreshold}
            minzoom={projectCloseupThreshold}
            paint={{
              'line-color': [
                'case',
                isBelowLowestDepth(minDepth ?? -1000),
                '#ef4447',
                ['case', isPrelabelExpression, '#005C49', '#00A887'],
              ],
              'line-width': ['case', isHoveredExpression, 12, 10],
            }}
            source={source}
            source-layer={sourceLayer}
            type="line"
          />
        </>
      )}
    </>
  );
};
