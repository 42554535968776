import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { DateTime, Duration } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, type AccordionControlProps } from '@components/Accordion';
import { Table, TableCellText, TableRow } from '@components/Table';
import { useCoordinates } from '@hooks/useCoordinate';
import { useFilters } from '@hooks/useFilters';
import { useLocalizedFormat } from '@hooks/useLocalizedFormat';
import { useMapFocus } from '@hooks/useMapFocus';
import { projectCloseupThreshold } from '@utils/mapboxExpressions';
import { localizedDateString, localizedDuration } from '@utils/timeFormatting';
import { trackEvent } from '@utils/trackEvent';

export type ScanInfo = {
  id: string;
  date: DateTime;
  duration: Duration;
  device: string;
  length: number | null;
};

type ScanInfoCardParams = AccordionControlProps & {
  scanId: string;
  info?: ScanInfo;
};

type ScanInfoExistsParams = AccordionControlProps & {
  info: ScanInfo;
};

export const ScanInfoCard = (params: ScanInfoCardParams) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    if (!isInitialLoading) return;
    setTimeout(() => setIsInitialLoading(false), 3000);
  }, [isInitialLoading]);

  if (!params.info) {
    return isInitialLoading ? <LoadingSpinner /> : <ScanInfoNotFound scanId={params.scanId} />;
  }

  return <ScanInfoExists info={params.info} {...params} />;
};

const ScanInfoExists = ({
  info,
  expanded,
  setExpanded,
  collapsable = true,
}: ScanInfoExistsParams) => {
  const { t, i18n } = useTranslation();
  const { formatAsUnit } = useLocalizedFormat();

  return (
    <Accordion
      collapsable={collapsable}
      expanded={expanded}
      setExpanded={setExpanded}
      title={t('components.scanInfoCard.title')}
    >
      <Table>
        <TableRow>
          <TableCellText>{t('components.scanInfoCard.info.id')}</TableCellText>
          <TableCellText>{info.id}</TableCellText>
        </TableRow>
        <TableRow>
          <TableCellText>{t('components.scanInfoCard.info.date')}</TableCellText>
          <TableCellText>{localizedDateString(info.date, { locale: i18n.language })}</TableCellText>
        </TableRow>
        <TableRow>
          <TableCellText>{t('components.scanInfoCard.info.duration')}</TableCellText>
          <TableCellText>{localizedDuration(info.duration, i18n.language)}</TableCellText>
        </TableRow>
        {info.length !== null && (
          <TableRow>
            <TableCellText>{t('components.scanInfoCard.info.trajectoryLength')}</TableCellText>
            <TableCellText>{formatAsUnit(info.length, 'meter')}</TableCellText>
          </TableRow>
        )}
        <TableRow>
          <TableCellText showBorder={!collapsable}>
            {t('components.scanInfoCard.info.device')}
          </TableCellText>
          <TableCellText showBorder={!collapsable}>{info.device}</TableCellText>
        </TableRow>
      </Table>
    </Accordion>
  );
};

// this component is shown, when no scan metadata is present
const ScanInfoNotFound = ({ scanId }: { scanId: string }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { setSidebar } = useFilters();

  if (isLoading) {
    return <ScanInfoSearching scanId={scanId} />;
  }

  // button click will summon ScanInfoSearching, which fetches scan location and moves camera over there
  const handleOnClick = () => {
    setSidebar(null);
    setIsLoading(true);
  };

  return (
    <Stack spacing="16px" sx={{ padding: '16px' }}>
      <Typography>{t('components.scanInfoCard.notFound.message')}</Typography>
      <Button onClick={handleOnClick} variant="contained">
        {t('components.scanInfoCard.notFound.navigateButton')}
      </Button>
    </Stack>
  );
};

// Tries to fetch scan location and moves camera to its coordinates.
// If coordinates could not be fetched, a text prompt is shown.
const ScanInfoSearching = ({ scanId }: { scanId: string }) => {
  const { t } = useTranslation();
  const { coordinates, status } = useCoordinates(scanId);
  const { focusLocation } = useMapFocus();
  const [isScanNotFound, setIsScanNotFound] = useState(false);

  // track outcome
  useEffect(() => {
    if (status === 'success') {
      trackEvent('navigateToScan', { scan_id: scanId });
    } else if (status === 'error') {
      trackEvent('scanNotFound', { scan_id: scanId });
      setTimeout(() => setIsScanNotFound(true), 1000);
    }
  }, [scanId, status]);

  useEffect(() => {
    if (!coordinates) return;
    focusLocation({ center: coordinates, minZoom: projectCloseupThreshold });
  }, [focusLocation, coordinates]);

  if (isScanNotFound) {
    return (
      <Typography sx={{ padding: '16px' }}>
        {t('components.scanInfoCard.searching.notFound')}
      </Typography>
    );
  }

  return <LoadingSpinner />;
};

// just a spinner, that is shown on init and when searching coordinates
export const LoadingSpinner = () => {
  return (
    <Stack alignItems="center" sx={{ paddingY: '16px', width: '100%' }}>
      <CircularProgress />
    </Stack>
  );
};
