import { Box } from '@mui/material';
import { cloneElement, type ReactElement, useCallback, useRef, useState } from 'react';
import {
  type ReactZoomPanPinchProps,
  type ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';

import { TopDownViewerHelp } from '@components/TopDownViewer/TopDownViewerHelp';
import { useResizeObserver } from '@hooks/useResizeObserver';
import { useThemeMode } from '@hooks/useThemeMode';

export const ImageZoom = ({
  image,
  padding = 40,
  ...props
}: Omit<ReactZoomPanPinchProps, 'children' | 'ref'> & {
  image: ReactElement;
  padding?: number;
}) => {
  const {
    theme: {
      palette: { grey },
    },
  } = useThemeMode();

  const zoomPanPinchRef = useRef<ReactZoomPanPinchRef>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement | HTMLDivElement | null>(null);

  const [minScale, setMinScale] = useState(1);
  const [enabled, setEnabled] = useState(false);

  const isHTMLImageElement = (
    element: HTMLImageElement | HTMLDivElement | null,
  ): element is HTMLImageElement => {
    return element instanceof HTMLImageElement;
  };

  const fitToWrapper = useCallback(async () => {
    if (!zoomPanPinchRef.current || !wrapperRef.current || !imgRef.current) {
      return;
    }

    zoomPanPinchRef.current.resetTransform(0);

    await new Promise<void>((resolve) => {
      if (imgRef.current && !isHTMLImageElement(imgRef.current)) resolve();
      else if (imgRef.current && imgRef.current.complete) resolve();
      else if (imgRef.current) imgRef.current.onload = () => resolve();
    });

    const wrapperWidth = wrapperRef.current.clientWidth - padding;
    const wrapperHeight = wrapperRef.current.clientHeight - padding;

    const imgWidth = isHTMLImageElement(imgRef.current)
      ? imgRef.current.width
      : imgRef.current.clientWidth;
    const imgHeight = isHTMLImageElement(imgRef.current)
      ? imgRef.current.height
      : imgRef.current.clientHeight;

    const scaleWidth = wrapperWidth / imgWidth;
    const scaleHeight = wrapperHeight / imgHeight;

    const scale = scaleWidth < scaleHeight ? scaleWidth : scaleHeight;

    zoomPanPinchRef.current.centerView(scale, 0);
    setMinScale(scale);
  }, [padding]);

  useResizeObserver(wrapperRef, fitToWrapper);

  const onRefChange = useCallback(
    async (node: HTMLImageElement | HTMLDivElement | null) => {
      imgRef.current = node;
      await fitToWrapper();

      setEnabled(node !== null && 'complete' in node);
    },
    [fitToWrapper],
  );

  return (
    <Box
      component="div"
      ref={wrapperRef}
      sx={{ width: '100%', height: '100%', position: 'relative' }}
    >
      <TransformWrapper
        disabled={!enabled}
        limitToBounds={true}
        minScale={minScale}
        onInit={fitToWrapper}
        panning={{ velocityDisabled: true }}
        ref={zoomPanPinchRef}
        {...props}
      >
        <TransformComponent
          wrapperStyle={{
            width: '100%',
            height: '100%',
            backgroundColor: grey[50],
          }}
        >
          {cloneElement(image, {
            ref: onRefChange,
          })}
        </TransformComponent>
      </TransformWrapper>
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TopDownViewerHelp />
      </Box>
    </Box>
  );
};
