import {
  Autocomplete,
  Checkbox,
  FormControl,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { type ProjectEntity, useApiProjects } from '@hooks/useApiProjects';
import { useFilters } from '@hooks/useFilters';

const intersection = (list: ProjectEntity[], ids: string[]) =>
  list.filter((projectItem) => ids.includes(projectItem.id));

export const ProjectMultiSelect = () => {
  const { data: projectEnvelope } = useApiProjects();

  if (!projectEnvelope?.data) {
    return null;
  }

  return <ProjectMultiSelectControl projects={projectEnvelope?.data} />;
};

export type ProjectAutocompleteControlProps = {
  projects: ProjectEntity[];
};

const ProjectMultiSelectControl = ({ projects }: ProjectAutocompleteControlProps) => {
  const { projectList, setProjectList } = useFilters();
  const { t } = useTranslation();

  const selectedProjects = useMemo(
    () => intersection(projects, projectList),
    [projectList, projects],
  );

  return (
    <FormControl fullWidth>
      <Autocomplete
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        limitTags={0}
        multiple
        onChange={(event, newValue) => {
          setProjectList(newValue.map((item) => item.id));
        }}
        options={projects}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('components.filterBox.projectSelect.label')}
            placeholder={t('components.filterBox.projectSelect.label')}
          />
        )}
        renderOption={(props, item, { selected }) => (
          <ListItem {...props} key={item.name}>
            <ListItemIcon>
              <Checkbox checked={selected} />
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        )}
        renderTags={(projects) => '+' + projects.length}
        selectOnFocus
        slotProps={{ paper: { elevation: 6 } }}
        value={selectedProjects}
      />
    </FormControl>
  );
};
