import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { getEnvironment } from '@utils/getEnvironment';

const env = getEnvironment();

const isDevelopment = env.dev;
const environment = env.mode;

const useSentry = !isDevelopment && (environment === 'test' || environment === 'production');

if (useSentry) {
  Sentry.init({
    dsn: env.sentryDsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: env.appVersion ?? 'unknown',
    environment,
  });
}
