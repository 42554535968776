import mapboxgl from 'mapbox-gl';
import { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';

import { useMapEvent } from '@hooks/useMapEvent';

export const useMapZoomIsAbove = (threshold: number) => {
  const [isZoomAboveThreshold, setIsZoomAboveThreshold] = useState(false);
  const { current: map } = useMap();
  const zoomCallback = useCallback(
    ({ target: map }: { target: mapboxgl.Map }) => {
      setIsZoomAboveThreshold(map.getZoom() > threshold);
    },
    [threshold],
  );

  // change value when map changes + use for initial value
  useEffect(() => map && setIsZoomAboveThreshold(map.getZoom() > threshold), [threshold, map]);

  useMapEvent('zoom', zoomCallback);

  return {
    isZoomAboveThreshold,
  };
};
