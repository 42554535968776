import { useTranslation } from 'react-i18next';

import { formatAsUnit } from '@utils/numberFormatting';

export const useLocalizedFormat = () => {
  const { i18n } = useTranslation();
  const getLocale = () => {
    if (i18n.language === 'de') {
      return 'de-DE';
    }
    if (i18n.language === 'en') {
      return 'en-GB';
    }
  };
  const locale = getLocale();

  const formatAsUnitLocalized = (number: number, unit: string | undefined) =>
    formatAsUnit(number, unit, locale);

  return { formatAsUnit: formatAsUnitLocalized };
};
