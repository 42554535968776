import { Typography } from '@mui/material';
import type { ReactNode } from 'react';

export type SidebarSectionProps = {
  children: ReactNode;
};

export const SidebarSection = ({ children }: SidebarSectionProps) => {
  return (
    <Typography
      sx={{
        margin: '0',
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        '& svg': { fontSize: '24px' },
      }}
      variant="subtitle2"
    >
      {children}
    </Typography>
  );
};
