export class FetchError extends Error {
  constructor(
    public res?: Response,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public error?: any,
    message?: string,
  ) {
    super(message);
  }
}
