import { useEffect } from 'react';
import { Layer } from 'react-map-gl';
import { useParams } from 'react-router-dom';

import { Image } from '@components/Image';
import { MapPopup } from '@components/MapPopup';
import { type VectorLayerProps } from '@components/MapSource/VectorLayerProps';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useFilters } from '@hooks/useFilters';
import { useMapFocus } from '@hooks/useMapFocus';
import { useMapHoverFeature } from '@hooks/useMapHoverFeature';
import { useMapImage } from '@hooks/useMapImage';
import { useMapSelectFeatures } from '@hooks/useMapSelectFeatures';
import { useNavigateExtended } from '@hooks/useNavigateExtended';
import { filterPhotoExpression, projectCloseupThreshold } from '@utils/mapboxExpressions';

export const Photos = ({ source, sourceLayer }: VectorLayerProps) => {
  const { photoId, photoIndex } = useParams();
  const photoIds = photoId?.split(',');
  const { feature: hoveredFeatureNear } = useMapHoverFeature('photos-near');

  useMapHoverFeature('photos-far');
  const { selectedFeatures } = useMapSelectFeatures(['photos-near']);
  const { location } = useMapSelectFeatures(['photos-far']);
  const filters = useFilters();
  const navigate = useNavigateExtended();
  const mapImage = useMapImage('/assets/map-icon-image.png');
  const mapImageSelected = useMapImage('/assets/map-icon-image-selected.png');
  const mapImageCluster = useMapImage('/assets/map-icon-image-cluster.png');
  const { focusLocation } = useMapFocus();

  const filter = filterPhotoExpression(filters);
  const { photosClusterZoomThreshold } = useFeatureFlags();

  useEffect(() => {
    const ids = selectedFeatures?.map((feature) => feature.id).join(',');

    if (ids) {
      navigate(`photo/${ids}/0`, {
        preserveSearch: true,
        preserveHash: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeatures]);

  useEffect(() => {
    if (location) {
      focusLocation({ center: location, zoom: photosClusterZoomThreshold });
    }
  }, [focusLocation, location, photosClusterZoomThreshold]);

  return (
    <>
      {hoveredFeatureNear && (
        <>
          <MapPopup>
            <Image id={hoveredFeatureNear.properties?.id} sx={{ display: 'block' }} width={100} />
          </MapPopup>
        </>
      )}
      {filters.showPhotos && (
        <>
          <Layer
            filter={filter}
            id="photos-far"
            layout={
              mapImageCluster
                ? {
                    'icon-image': mapImageCluster,
                    'icon-ignore-placement': false,
                    'icon-allow-overlap': false,
                    'icon-padding': 0,
                  }
                : {}
            }
            maxzoom={photosClusterZoomThreshold}
            minzoom={projectCloseupThreshold}
            source={source}
            source-layer={sourceLayer}
            type="symbol"
          />
          <Layer
            beforeId="photos-far"
            filter={filter}
            id="photos-near"
            layout={
              mapImageSelected && mapImage
                ? {
                    'icon-image': [
                      'match',
                      ['to-string', ['get', 'id']],
                      photoIds?.[!Number.isNaN(Number(photoIndex)) ? Number(photoIndex) : 0] ?? '',
                      mapImageSelected,
                      mapImage,
                    ],
                    'icon-ignore-placement': true,
                    'icon-allow-overlap': true,
                    'icon-padding': 0,
                  }
                : {}
            }
            minzoom={photosClusterZoomThreshold}
            paint={{
              'icon-translate': [2, 2],
            }}
            source={source}
            source-layer={sourceLayer}
            type="symbol"
          />
        </>
      )}
    </>
  );
};
