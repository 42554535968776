import mapboxgl, { type MapboxGeoJSONFeature } from 'mapbox-gl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMap } from 'react-map-gl';

import { useMapEvent } from '@hooks/useMapEvent';
import { type MouseMoveEvent, useMapLayerInteraction } from '@hooks/useMapLayerInteraction';
import { isInZoomRange } from '@utils/isInZoomRange';

// don't use an array as layer, because the mouseleave won't trigger sometimes
export const useMapHoverFeature = (layer: string) => {
  const [feature, setFeature] = useState<MapboxGeoJSONFeature | null>(null);
  const { current: map } = useMap();
  const time = useRef<number>();

  // select hovered feature when mouse moves on layer
  const onMouseMove = useCallback(
    (event: MouseMoveEvent) => {
      // if the originalEvent is prevented set feature to null and return
      if (event.originalEvent.defaultPrevented) {
        setFeature(null);

        return;
      }

      // here the original event is prevented
      // in this way the same mouse move event for another layer will get prevented if this comes first
      // and only one layer can be hovered at a time
      // for this to work, the order of the layers are important -> see MapSource.tsx
      event.originalEvent.preventDefault();

      const map = event.target;
      // filter out all feature out of (min/max) zoom
      const first = event.features?.filter((f) => isInZoomRange(map, f.layer))?.[0];

      if (!first) {
        // there is no feature to hover
        setFeature(null);
      } else if (feature?.id !== first.id) {
        // feature changed
        setFeature(first);
      }
    },
    [feature],
  );

  // deselect feature when zoom is out of allowed range
  const onZoom = useCallback(
    ({ target: map }: { target: mapboxgl.Map }) => {
      if (feature && !isInZoomRange(map, feature.layer)) {
        setFeature(null);
      }
    },
    [feature],
  );

  useMapEvent('zoom', onZoom);

  // deselect feature when mouse leaves layer
  const onMouseLeave = useCallback(() => {
    window.clearTimeout(time.current);

    time.current = window.setTimeout(() => {
      setFeature(null);
    }, 150);
  }, []);

  // attach mouse listeners to map
  useMapLayerInteraction('mousemove', layer, onMouseMove);
  useMapLayerInteraction('mouseleave', layer, onMouseLeave);

  useEffect(() => {
    const style = map?.getCanvas().style;

    if (!style) return;

    style.cursor = feature ? 'pointer' : 'unset';
  }, [map, feature]);

  return { feature };
};
