import { CircleInformationOutline } from '@deepup/icons';
import { Chip, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HtmlTooltip } from '@components/HtmlTooltip';

export const TopDownViewerHelp = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <HtmlTooltip
      placement="bottom-end"
      title={
        <>
          <Typography sx={{ marginBottom: '10px' }}>
            {t('components.topDownViewer.help.usage')}
          </Typography>
          <Grid
            container
            sx={{
              padding: '5px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography>{t('components.topDownViewer.help.move')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Chip label={t('components.topDownViewer.help.mouseLeft')} variant="outlined" />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              padding: '5px',
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <Typography>{t('components.topDownViewer.help.zoom')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Chip label={t('components.topDownViewer.help.mouseWheel')} variant="outlined" />
            </Grid>
          </Grid>
        </>
      }
    >
      <IconButton
        sx={(theme) => ({
          background:
            theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.7)' : 'rgba(220, 220, 220, 0.7)',
          '&:hover': {
            background:
              theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.3)' : 'rgba(200, 200, 200, 0.7)',
          },
        })}
      >
        <CircleInformationOutline fill={theme.palette.text.primary} />
      </IconButton>
    </HtmlTooltip>
  );
};
