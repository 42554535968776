import { bbox } from '@turf/bbox';
import type { MapboxGeoJSONFeature } from 'mapbox-gl';
import { useCallback } from 'react';
import { useMap } from 'react-map-gl';

import { bboxToBounds } from '@utils/bboxToBounds';

type FeatureZoom = {
  mapZoom: number | null;
  featureZoom: number | null;
  exceedsMapZoom: boolean | null;
};

export const useMapFeatureZoom = () => {
  const { current: map } = useMap();

  const getFeatureZoom = useCallback(
    (feature: MapboxGeoJSONFeature): FeatureZoom => {
      if (!map) {
        return { exceedsMapZoom: null, featureZoom: null, mapZoom: null };
      }
      const currentZoom = map.getZoom();

      const bounds = bboxToBounds(feature.bbox ?? bbox(feature));

      const featureZoom = map.cameraForBounds(bounds)?.zoom;

      if (!featureZoom) {
        return { exceedsMapZoom: null, featureZoom: null, mapZoom: null };
      }

      return { featureZoom, mapZoom: currentZoom, exceedsMapZoom: currentZoom >= featureZoom };
    },
    [map],
  );

  return {
    getFeatureZoom,
  };
};
