import { CardContent, Divider, Paper, Stack } from '@mui/material';
import type { FeatureCollection } from 'geojson';
import type { MapboxGeoJSONFeature } from 'mapbox-gl';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterBoxBilling } from '@components/FilterBox';
import { Map } from '@components/Map';
import { SidebarHeader, SidebarHeaderText } from '@components/Sidebar';
import { useApiWfs } from '@hooks/useApiWfs';
import { useFeaturesFiltered } from '@hooks/useFeaturesFiltered';
import { useFeaturesFocus } from '@hooks/useFeaturesFocus';
import { useFeaturesLength } from '@hooks/useFeaturesLength';
import { useFilters } from '@hooks/useFilters';
import { useLocalizedFormat } from '@hooks/useLocalizedFormat';
import { MapSourceWfs } from '@pages/billing/MapSourceWfs';

const useReplaceNullables = (
  data: FeatureCollection | undefined,
): FeatureCollection | undefined => {
  return useMemo(() => {
    if (!data) {
      return undefined;
    }

    const features = data.features.map((value) => ({
      ...value,
      properties: {
        ...value.properties,
        depth: value.properties?.['depth'] ?? 0,
        width: value.properties?.['width'] ?? 0,
        surface_type: value.properties?.['surface_type'] ?? 'undefined',
      },
    }));

    return { type: 'FeatureCollection', features };
  }, [data]);
};

export const Page = () => {
  const filters = useFilters();
  const { t } = useTranslation();
  const { formatAsUnit } = useLocalizedFormat();

  const { data: rawData } = useApiWfs({ projectId: filters.projectId ?? '' });
  const data = useReplaceNullables(rawData);

  const dataFiltered = useFeaturesFiltered({ data, filters });
  const totalLength = useFeaturesLength({ data: dataFiltered });

  const initialViewState = {
    zoom: 5.42,
    latitude: 51.1427959254,
    longitude: 9.8,
  };

  useFeaturesFocus({ data: dataFiltered, mapId: 'map', initialViewState });

  return (
    <Stack
      direction="row"
      divider={<Divider flexItem orientation="vertical" />}
      sx={{ height: '100%' }}
    >
      <FilterBoxBilling
        features={(data?.features ?? []) as MapboxGeoJSONFeature[]}
        filters={filters}
      />
      <Map id="map" initialViewState={initialViewState} isInteractive={false}>
        <MapSourceWfs data={data} filters={filters} />
      </Map>
      <Paper elevation={0} square sx={{ minWidth: '20%', overflow: 'auto' }}>
        <SidebarHeader>
          <SidebarHeaderText>{t('pages.billing.sidebarHeaderText')}</SidebarHeaderText>
        </SidebarHeader>
        <CardContent>
          {totalLength !== null
            ? `${t('pages.billing.fullLength')}: ${formatAsUnit(totalLength * 1000, 'meter')}`
            : t('pages.billing.noLength')}
        </CardContent>
      </Paper>
    </Stack>
  );
};
