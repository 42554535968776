import { useAuth0, type Auth0ContextInterface } from '@auth0/auth0-react';
import type { Manifest } from '@deepup/viewer3d';
import { useQuery } from '@tanstack/react-query';

import { FetchError } from '@utils/FetchError';
import { getEnvironment } from '@utils/getEnvironment';

const get = async (auth: Auth0ContextInterface, scanId: string) => {
  const { apiTrenchviewer } = getEnvironment();

  const token = await auth.getAccessTokenSilently();
  const response = await fetch(`${apiTrenchviewer}scans/${scanId}/hd-mesh`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const responseJson = await response.json();

  if (response.ok) {
    return responseJson;
  }

  if (!response.ok) {
    throw new FetchError(response, responseJson);
  }
};

export const useApiHdMesh = (scanId: string) => {
  const auth0 = useAuth0();

  return useQuery<Manifest, FetchError | Error>({
    queryKey: ['useApiHdMesh', scanId],
    queryFn: () => get(auth0, scanId),
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  });
};
