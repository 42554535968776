import { useCallback, useMemo, useRef } from 'react';
import { createSearchParams, type URLSearchParamsInit, useLocation } from 'react-router-dom';

import { useNavigateExtended, type NavigateOptionsExtended } from '@hooks/useNavigateExtended';

export type SetURLSearchParamsExtended = (
  nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
  navigateOpts?: NavigateOptionsExtended,
) => void;

function getSearchParamsForLocation(
  locationSearch: string,
  defaultSearchParams: URLSearchParams | null,
) {
  const searchParams = createSearchParams(locationSearch);

  if (defaultSearchParams) {
    // Use `defaultSearchParams.forEach(...)` here instead of iterating of
    // `defaultSearchParams.keys()` to work-around a bug in Firefox related to
    // web extensions. Relevant Bugzilla tickets:
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1414602
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1023984
    defaultSearchParams.forEach((_, key) => {
      if (!searchParams.has(key)) {
        defaultSearchParams.getAll(key).forEach((value) => {
          searchParams.append(key, value);
        });
      }
    });
  }

  return searchParams;
}

export function useSearchParamsExtended(
  defaultInit?: URLSearchParamsInit,
): [URLSearchParams, SetURLSearchParamsExtended] {
  const defaultSearchParamsRef = useRef(createSearchParams(defaultInit));
  const hasSetSearchParamsRef = useRef(false);

  const location = useLocation();
  const searchParams = useMemo(
    () =>
      // Only merge in the defaults if we haven't yet called setSearchParams.
      // Once we call that we want those to take precedence, otherwise you can't
      // remove a param with setSearchParams({}) if it has an initial value
      getSearchParamsForLocation(
        location.search,
        hasSetSearchParamsRef.current ? null : defaultSearchParamsRef.current,
      ),
    [location.search],
  );

  const navigate = useNavigateExtended();
  const setSearchParams = useCallback<SetURLSearchParamsExtended>(
    (nextInit, navigateOptions) => {
      const newSearchParams = createSearchParams(
        typeof nextInit === 'function' ? nextInit(searchParams) : nextInit,
      );

      hasSetSearchParamsRef.current = true;
      navigate(`${location.pathname}?${newSearchParams}`, navigateOptions);
    },
    [location.pathname, navigate, searchParams],
  );

  return [searchParams, setSearchParams];
}
