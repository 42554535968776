import { DateTime } from 'luxon';
import { type MapboxGeoJSONFeature } from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type PhotoDetails, useApiPhoto } from '@hooks/useApiPhoto';
import { useMapFeatures } from '@hooks/useMapFeatures';

export type PhotoInfo = {
  id: string;
  address?: string;
  category?: string;
  date: string;
  device: string;
  solution: string;
  text?: string;
};

export type FetchState = 'success' | 'loading' | 'failed';

export const useMapSolution = (solution?: string) => {
  const { t } = useTranslation();

  if (!solution) return;

  const map = {
    NONE: t('hooks.useFetchPhotoInfo.NONE'),
    CODE: t('hooks.useFetchPhotoInfo.CODE'),
    CORELOCATION: t('hooks.useFetchPhotoInfo.CORELOCATION'),
    DGNSS: t('hooks.useFetchPhotoInfo.DGNSS'),
    FLOAT: t('hooks.useFetchPhotoInfo.FLOAT'),
    FIXED: t('hooks.useFetchPhotoInfo.FIXED'),
  };

  const mapped = map[solution as keyof typeof map];

  return mapped ?? t('hooks.useFetchPhotoInfo.unknown');
};

const featureToPhotoInfo = (feature: MapboxGeoJSONFeature | undefined): PhotoInfo | undefined => {
  if (
    feature &&
    feature.id &&
    feature.properties &&
    feature.properties['date'] &&
    feature.properties['scan_device_name'] &&
    feature.properties['solution']
  ) {
    const date = `${DateTime.fromSQL(feature.properties['date'], { zone: 'utc' })
      .reconfigure({ locale: 'de-DE' })
      .setZone('cet')
      .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)} CET`;

    return {
      id: feature.id.toString(),
      address: feature.properties['address'] ?? undefined,
      category: feature.properties['category_name'] ?? undefined,
      date,
      device: feature.properties['scan_device_name'],
      solution: feature.properties['solution'],
      text: feature.properties['text'] ?? undefined,
    };
  }

  return undefined;
};

export const detailsToPhotoInfo = (data: PhotoDetails): PhotoInfo => {
  const date = `${data.date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)} CET`;

  return {
    id: data.id,
    address: data.address,
    category: data.categoryName,
    date,
    device: data.scanDeviceName,
    solution: data.solution,
    text: data.text,
  };
};

export type Options = {
  timeoutUntilApiFetch: number;
  source: string;
  sourceLayer?: string;
};

export const useFetchPhotoInfo = (
  photoId: string,
  options: Options = {
    timeoutUntilApiFetch: 3000,
    source: 'photos-source',
    sourceLayer: 'photo',
  },
) => {
  const [photoInfo, setPhotoInfo] = useState<PhotoInfo>();
  const [fetchState, setFetchState] = useState<FetchState>('loading');

  const timeoutId = useRef<number>();
  const [needsApiFetch, setNeedsApiFetch] = useState(false);

  useEffect(() => {
    setNeedsApiFetch(false);
    setPhotoInfo(undefined);
    setFetchState('loading');

    window.clearTimeout(timeoutId.current);
    timeoutId.current = window.setTimeout(
      () => setNeedsApiFetch(true),
      options.timeoutUntilApiFetch,
    );
  }, [options.timeoutUntilApiFetch, photoId]);

  const features = useMapFeatures({ ...options, filter: ['==', 'id', photoId] });

  useEffect(() => {
    const info = features[0] && featureToPhotoInfo(features[0]);

    if (!info) {
      return;
    }
    setPhotoInfo(info);
    setFetchState('success');
  }, [photoId, features]);

  const {
    data: queryResult,
    isSuccess,
    isError,
  } = useApiPhoto(photoId, { enabled: !photoInfo && needsApiFetch });

  useEffect(() => {
    if (isSuccess) {
      setFetchState('success');
      setPhotoInfo(detailsToPhotoInfo(queryResult));
    } else if (isError) {
      setFetchState('failed');
    }
  }, [queryResult, isSuccess, isError]);

  const mappedSolution = useMapSolution(photoInfo?.solution);

  if (!photoInfo || !mappedSolution) {
    return {
      photoInfo: undefined,
      fetchState,
    };
  }

  const mappedPhotoInfo = {
    ...photoInfo,
    solution: mappedSolution,
  };

  return {
    photoInfo: mappedPhotoInfo,
    fetchState,
  };
};
