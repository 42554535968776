import { useEffect } from 'react';
import { Layer } from 'react-map-gl';
import { useParams } from 'react-router-dom';

import { type VectorLayerProps } from '@components/MapSource/VectorLayerProps';
import { useFilters } from '@hooks/useFilters';
import { useMapFeatureState } from '@hooks/useMapFeatureState';
import { useMapHoverFeature } from '@hooks/useMapHoverFeature';
import { useMapSelectFeatures } from '@hooks/useMapSelectFeatures';
import { useNavigateExtended } from '@hooks/useNavigateExtended';
import {
  filterTrajectoryExpression,
  isHoveredExpression,
  isScanExpression,
  projectCloseupThreshold,
} from '@utils/mapboxExpressions';

export const Trajectories = ({ source, sourceLayer }: VectorLayerProps) => {
  const { scanId, elementType } = useParams();
  const { feature: hoveredFeature } = useMapHoverFeature('trajectories-outline');
  const { selectedFeature } = useMapSelectFeatures(['trajectories-outline']);
  const navigate = useNavigateExtended();
  const filters = useFilters();

  const filter = filterTrajectoryExpression(filters);

  useEffect(() => {
    const scanId = selectedFeature?.properties?.scan_id;

    if (scanId) {
      navigate(`scan/${scanId}/trajectory/top-down-view`, {
        preserveSearch: true,
        preserveHash: true,
      });
    }
    // https://github.com/remix-run/react-router/issues/7634
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);

  // add state to highlight feature
  useMapFeatureState(hoveredFeature, { hover: true });

  const isTrajectoryExpression = isScanExpression(scanId, elementType, 'trajectory');

  if (!filters.showScans) {
    return null;
  }

  return (
    <>
      <Layer
        filter={filter}
        id="trajectories-outline"
        layout={{
          'line-sort-key': 1,
          'line-join': 'round',
          'line-cap': 'round',
        }}
        minzoom={projectCloseupThreshold}
        paint={{
          'line-color': '#00A887',
          'line-width': 8,
          'line-opacity': ['case', isHoveredExpression, 0.6, 0],
        }}
        source={source}
        source-layer={sourceLayer}
        type="line"
      />
      <Layer
        beforeId="trajectories-outline"
        filter={filter}
        id="trajectories"
        layout={{
          'line-sort-key': 2,
          'line-join': 'round',
          'line-cap': 'round',
        }}
        minzoom={projectCloseupThreshold}
        paint={{
          'line-color': ['case', isTrajectoryExpression, '#005C49', '#00A887'],
          'line-width': ['case', isTrajectoryExpression, 8, 4],
          'line-opacity': 0.8,
        }}
        source={source}
        source-layer={sourceLayer}
        type="line"
      />
    </>
  );
};
