import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { type ProjectEntity } from '@hooks/useApiProjects';
import { type FilterValues } from '@hooks/useFilters';

export type ProjectSelectProps = {
  projectId: FilterValues['projectId'];
  setProjectId: FilterValues['setProjectId'];
  projects: ProjectEntity[];
};

export const ProjectSelect = ({ projectId, setProjectId, projects }: ProjectSelectProps) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel id="project-select-label">
        {t('components.filterBox.projectSelect.label')}
      </InputLabel>
      <Select
        label={t('components.filterBox.projectSelect.label')}
        labelId="project-select-label"
        onChange={(event) => setProjectId(event.target.value)}
        value={projectId ?? ''}
      >
        <MenuItem value="">-</MenuItem>
        {projects.length &&
          projects.map((project) => (
            <MenuItem key={project.id} value={project.id}>
              {project.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
