import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { type AccordionControlProps } from '@components/Accordion';
import { Image } from '@components/Image';
import { ImageCarousel } from '@components/ImageCarousel';
import { ImageDownload } from '@components/ImageDownload';
import { ImageZoom } from '@components/ImageZoom';
import { PhotoInfoCard } from '@components/PhotoInfoCard';
import { Sidebar } from '@components/Sidebar';
import { useFetchPhotoInfo } from '@hooks/useFetchPhotoInfo';
import { useFilters } from '@hooks/useFilters';
import { useNavigateExtended } from '@hooks/useNavigateExtended';
import { useThemeMode } from '@hooks/useThemeMode';
import { trackEvent } from '@utils/trackEvent';

export const Page = () => {
  const { t } = useTranslation();
  const { photoId, photoIndex } = useParams();
  const photoIds = photoId?.split(',');

  const navigate = useNavigateExtended();
  const { sidebar, setSidebar } = useFilters();

  const isFullscreen = sidebar === 'fullscreen';

  const [expanded, setExpanded] = useState(isFullscreen);

  const onSidebarClose = () => {
    navigate(`../../..`, {
      relative: 'path',
      preserveHash: true,
      preserveSearch: true,
      preserveSearchExclude: ['sidebar'],
    });
    trackEvent('sidebarClosed');
  };

  return (
    <Sidebar
      key={photoIds?.join(',')}
      onClose={onSidebarClose}
      setSidebar={setSidebar}
      sidebar={sidebar}
    >
      {photoIds && photoIds.length === 1 && (
        <PhotoWithInfo
          collapsable={!isFullscreen}
          expanded={expanded}
          isFullscreen={isFullscreen}
          photoId={photoIds[0]}
          setExpanded={setExpanded}
        />
      )}
      {photoIds && photoIds.length > 1 && (
        <ImageCarousel
          index={!Number.isNaN(Number(photoIndex)) ? Number(photoIndex) : 0}
          label={t('pages.map.photo.imageCarouselLabel')}
          onSlideChange={(index) => {
            navigate(`../${index}`, {
              relative: 'path',
              preserveSearch: true,
              preserveHash: true,
            });
          }}
          slides={photoIds.map((photoId) => (
            <PhotoWithInfo
              collapsable={!isFullscreen}
              expanded={expanded}
              isFullscreen={isFullscreen}
              key={photoId}
              photoId={photoId}
              setExpanded={setExpanded}
            />
          ))}
          sx={{ height: '100%', width: '100%' }}
          sxControls={{
            alignItems: 'start',
            top: expanded && !isFullscreen ? 'max(100px, 23%)' : '42%',
            height: 'auto',
            left: isFullscreen ? '10px' : '26px',
            right: isFullscreen ? 'calc(min(30%, 400px) + 10px)' : '26px',
          }}
          sxDisplay={{
            width: isFullscreen ? 'calc(100% - min(30%, 400px))' : '100%',
          }}
        />
      )}
    </Sidebar>
  );
};

type PhotoWithInfoProps = AccordionControlProps & {
  photoId: string;
  isFullscreen: boolean;
};

const PhotoWithInfo = ({
  photoId,
  isFullscreen,
  collapsable,
  expanded,
  setExpanded,
}: PhotoWithInfoProps) => {
  const { t } = useTranslation();
  const {
    theme: {
      palette: { grey },
    },
  } = useThemeMode();

  const { photoInfo, fetchState } = useFetchPhotoInfo(photoId);

  const imageName = useMemo(() => {
    if (!photoInfo || !photoInfo.address) {
      return photoId;
    }

    return `${photoId}_${photoInfo.address.replace(/[^a-z0-9]/gi, '-').toLowerCase()}`;
  }, [photoId, photoInfo]);

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: isFullscreen ? 'row' : 'column',
        padding: isFullscreen ? 0 : '16px',
        overflow: 'auto',
      }}
    >
      <Box
        component="div"
        sx={{
          flex: 1,
          height: '100%',
          width: '100%',
          minHeight: '200px',
          background: grey[50],
          borderRadius: isFullscreen ? '0' : '10px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <ImageDownload
          id={photoId}
          name={imageName}
          options={{ stripMetadata: false }}
          sx={{ position: 'absolute', top: '10px', right: '60px', zIndex: 1 }}
        />
        <ImageZoom
          image={
            <Image
              alt={`${t('pages.map.photo.imageAltCategory')}:${photoInfo?.category}`}
              height={2000}
              id={photoId}
              sx={{
                width: '100%',
                height: '100%',
                display: 'block',
              }}
              sxLoading={{
                width: '100%',
                height: '700px',
              }}
            />
          }
          padding={0}
        />
      </Box>
      <Box
        component="div"
        sx={{
          width: isFullscreen ? 'min(30%, 400px)' : 'auto',
          overflowY: 'auto',
        }}
      >
        {fetchState === 'success' && (
          <PhotoInfoCard
            collapsable={collapsable}
            expanded={expanded}
            info={photoInfo}
            setExpanded={setExpanded}
          />
        )}
        {fetchState === 'loading' && (
          <Stack alignItems="center" sx={{ paddingY: '16px', width: '100%' }}>
            <CircularProgress />
          </Stack>
        )}
        {fetchState === 'failed' && (
          <Typography sx={{ padding: '16px' }}>{t('pages.map.photo.imageNotFound')}</Typography>
        )}
      </Box>
    </Box>
  );
};
