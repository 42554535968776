export type EventName =
  | 'mapSelectFeature'
  | 'meshViewerOpened'
  | 'sidebarClosed'
  | 'topDownViewerOpened'
  | 'measurementStarted'
  | 'measurementCompleted'
  | 'userEmailDomain'
  | 'mapGeocoderResult'
  | 'featureFlags'
  | 'navigateToScan'
  | 'scanNotFound'
  | 'photoDownloadSuccess'
  | 'photoDownloadFailed';

export const trackEvent = (eventName: EventName, data: { [key: string]: unknown } = {}) => {
  window.dataLayer?.push({
    event: eventName,
    ...data,
    _clear: true,
  });
};
