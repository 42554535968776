import { useAuth0 } from '@auth0/auth0-react';
import { type ReactNode, useEffect, useState } from 'react';
import {
  Map as MapGl,
  type MapProps as MapGlProps,
  Marker,
  NavigationControl,
  ScaleControl,
} from 'react-map-gl';

import { useFilters } from '@hooks/useFilters';
import { useThemeMode } from '@hooks/useThemeMode';
import { getEnvironment } from '@utils/getEnvironment';

import 'mapbox-gl/dist/mapbox-gl.css';
import './Map.css';

export type MapProps = {
  children: ReactNode;
  id?: string;
  isInteractive?: boolean;
  initialViewState?: MapGlProps['initialViewState'];
};

export const Map = ({ children, id, initialViewState, isInteractive = true }: MapProps) => {
  const [bearerToken, setBearerToken] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const { isDarkMode } = useThemeMode();
  const { satelliteView, marker } = useFilters();

  useEffect(() => {
    const getBearerToken = async () => {
      const token = await getAccessTokenSilently();

      setBearerToken(token);
    };

    getBearerToken();
  }, [getAccessTokenSilently]);

  if (!bearerToken) {
    return null;
  }

  return (
    <MapGl
      dragRotate={false}
      hash={isInteractive}
      id={id}
      initialViewState={
        initialViewState
          ? initialViewState
          : /* initial view state set to germsany */
            {
              zoom: 5.42,
              latitude: 51.1427959254,
              longitude: 7.8,
            }
      }
      interactive={isInteractive}
      mapStyle={
        satelliteView
          ? 'mapbox://styles/deepup/cltoboe0p000w01qvgny3dy16'
          : isDarkMode
            ? 'mapbox://styles/deepup/clto3o7gc003x01qw33z06s6l'
            : 'mapbox://styles/deepup/clto5zh19000101qv403t638q'
      }
      mapboxAccessToken={getEnvironment().mapboxAccessToken}
      onError={(err) => console.error('Mapbox internal error', err)}
      projection={{ name: 'mercator' }}
      style={{ width: '100%', flex: '1', height: '100%' }}
      touchZoomRotate={false}
      transformRequest={(url) => {
        return {
          url,
          headers: url.includes('api.mapbox')
            ? undefined
            : {
                Authorization: `Bearer ${bearerToken}`,
              },
        };
      }}
    >
      {isInteractive && (
        <>
          <ScaleControl position="bottom-right" />
          <NavigationControl position="bottom-right" showCompass={false} />
        </>
      )}
      {marker && <Marker latitude={marker.lat} longitude={marker.lng} />}
      {children}
    </MapGl>
  );
};
