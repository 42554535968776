import { photo as photoApi, timestamp as timestampApi } from '@deepup/apis';
import { DateTime } from 'luxon';

import { fromTimestamp, localizeTimestampApi } from '@utils/timeFormatting';

import type { DateRange } from './PhotoDownload';

export const isDateInRange = (dateRange: DateRange) => (photo: photoApi.Photo) => {
  if (!photo.recordedAt) {
    return false;
  }
  const dateAsDateTime = DateTime.fromJSDate(
    timestampApi.Timestamp.toDate(photo.recordedAt),
  ).startOf('day');

  if (dateRange.to && dateAsDateTime > dateRange.to.startOf('day')) {
    return false;
  }
  if (dateRange.from && dateAsDateTime < dateRange.from.startOf('day')) {
    return false;
  }

  return true;
};

export const isInTextSearch = (textSearch: string) => (photo: photoApi.Photo) => {
  return [
    photo.location?.city,
    photo.location?.place,
    photo.comments?.map((item) => item.text).join(' '),
    photo.categoryName,
    localizeTimestampApi(photo.recordedAt),
    photo.deviceId,
  ]
    .map((value) => value || '')
    .join(' ')
    .toLowerCase()
    .includes(textSearch.toLowerCase());
};

export const isInScanDevices =
  (scanDevices: string[], maxCount: number) => (photo: photoApi.Photo) => {
    if (scanDevices.length === 0) {
      return true; // all selected
    }
    if (scanDevices.length === maxCount) {
      return false; // all unselected
    }

    return scanDevices.includes(photo.deviceId);
  };

export const isInCategories =
  (photoCategories: string[], maxCount: number) => (photo: photoApi.Photo) => {
    if (photoCategories.length === 0) {
      return true; // all selected
    }
    if (photoCategories.length === maxCount) {
      return false; // all unselected
    }

    return photoCategories.includes(photo.categoryName);
  };

export const getPhotoFilename = (photo: photoApi.Photo) => {
  const parts = [
    photo.location?.place.replace(/\W+/g, '_'),
    photo.location?.city.replace(/\W+/g, '_'),
    photo.recordedAt
      ? fromTimestamp(photo.recordedAt).setZone('Europe/Berlin').toFormat('yyyy-MM-dd_HH-mm')
      : undefined,
    photo.categoryName.replace(/\W+/g, '_'),
  ];

  return parts.filter(Boolean).join('_');
};
