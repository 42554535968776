import mapboxgl, { type Expression, type MapboxGeoJSONFeature } from 'mapbox-gl';
import { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';

import { useMapEvent } from '@hooks/useMapEvent';

type UseMapFeaturesParams = {
  source: string;
  sourceLayer?: string;
  filter?: Expression;
};

export const useMapFeatures = (...params: UseMapFeaturesParams[]) => {
  const [features, setFeatures] = useState<MapboxGeoJSONFeature[]>([]);
  const { current: map } = useMap();

  const stringifiedParams = JSON.stringify(params);

  const callback = useCallback(
    ({ target: map }: { target: mapboxgl.Map }) => {
      const features = params.flatMap(({ source, sourceLayer, filter }) =>
        map.querySourceFeatures(source, {
          sourceLayer,
          filter,
        }),
      );

      setFeatures(features);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [stringifiedParams],
  );

  useEffect(() => {
    // This executes the callback immediately if the map is already loaded
    const mapInstance = map?.getMap();

    if (
      mapInstance &&
      params.every((p) => mapInstance.getSource(p.source) && mapInstance.isSourceLoaded(p.source))
    ) {
      callback({ target: mapInstance });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [map, callback]);

  useMapEvent('idle', callback);

  return features;
};
