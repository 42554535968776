import type { FeatureCollection, Feature } from 'geojson';

import { type Color, isDefined, isEmpty, removeDuplicatesBy, toColor, toNumber } from '@utils/core';

const elementTypes = ['MICRO_DUCT', 'MICRO_DUCT_BUNDLE'];

type ElementType = (typeof elementTypes)[number];

export type ElementInfo = {
  id: string;
  type: ElementType;
  length: number;
  name: string;
  description?: string;
  projectId: string;
  parentNetworkNodeId?: string;
  microDuctCount?: number;
  innerDiameter?: number;
  outerDiameter?: number;
  primaryColor?: Color;
  secondaryColor?: Color;
};

const toElement = ({ properties }: Feature): ElementInfo | undefined => {
  if (!properties) return;

  const {
    fid,
    name,
    description,
    type,
    length,
    project_id,
    parent_network_node_fid,
    micro_duct_count,
    material_inner_diameter,
    material_outer_diameter,
    material_primary_color,
    material_secondary_color,
  } = properties;

  if (
    typeof fid !== 'string' ||
    !elementTypes.includes(type) ||
    isNaN(length) ||
    typeof project_id !== 'string'
  ) {
    return;
  }

  return {
    id: fid,
    type,
    name,
    description,
    projectId: project_id,
    parentNetworkNodeId: parent_network_node_fid,
    length: toNumber(length)!,
    microDuctCount: toNumber(micro_duct_count),
    innerDiameter: toNumber(material_inner_diameter),
    outerDiameter: toNumber(material_outer_diameter),
    primaryColor: toColor(material_primary_color),
    secondaryColor: toColor(material_secondary_color),
  };
};

export const toElements = (featureCollection: FeatureCollection) => {
  const elements = featureCollection.features
    .map(toElement)
    .filter(isDefined<ElementInfo>)
    .filter(removeDuplicatesBy('id'));

  if (isEmpty(elements)) {
    return [];
  }

  return elements;
};
