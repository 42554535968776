import { useParams } from 'react-router-dom';

import { FeatureInfoCard } from '@components/FeatureInfoCard';
import { LoadingSpinner } from '@components/ScanInfoCard';
import { Sidebar } from '@components/Sidebar';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useFilters } from '@hooks/useFilters';
import { useNavigateExtended } from '@hooks/useNavigateExtended';
import { trackEvent } from '@utils/trackEvent';

export const Page = () => {
  const { featureId } = useParams();

  const navigate = useNavigateExtended();
  const { sidebar, setSidebar } = useFilters();

  const { isPlandataEnabled } = useFeatureFlags();

  if (!isPlandataEnabled) {
    return null;
  }

  const onSidebarClose = () => {
    navigate(`../..`, {
      relative: 'path',
      preserveHash: true,
      preserveSearch: true,
      preserveSearchExclude: ['sidebar'],
    });
    trackEvent('sidebarClosed');
  };

  return (
    <Sidebar key={featureId} onClose={onSidebarClose} setSidebar={setSidebar} sidebar={sidebar}>
      {!featureId ? <LoadingSpinner /> : <FeatureInfoCard featureId={Number(featureId)} />}
    </Sidebar>
  );
};
