import type { FeatureIdentifier } from 'mapbox-gl';
import { useEffect } from 'react';
import { useMap } from 'react-map-gl';

export type MapboxFeatureStateValue = string | number | boolean;

export const useMapFeatureState = (
  feature: FeatureIdentifier | null,
  state: { [p: string]: MapboxFeatureStateValue },
) => {
  const { current: map } = useMap();

  // add hover feature state to feature
  useEffect(() => {
    if (!(feature && map && feature.id)) return;

    map.setFeatureState(feature, state);

    return () => {
      const states = map.getFeatureState(feature);

      Object.keys(states).forEach((key) => {
        state?.[key] && map.removeFeatureState(feature, key);
      });
    };
  }, [state, feature, map]);
};
