import { isNumber } from '@turf/helpers';
import type { Feature } from 'geojson';
import { useMemo } from 'react';

import type { FilterStates } from '@hooks/useFilters';

export type UseFeaturesFilteredProps = {
  data?: { features: Feature[] };
  filters: FilterStates;
};

export type MatchFiltersProps = {
  feature: Feature;
  filters: FilterStates;
};

const matchFilters = ({ feature, filters }: MatchFiltersProps) => {
  if (
    filters.dateRange.from &&
    feature.properties?.['scan_date'] &&
    (feature.properties['scan_date'] as string).slice(0, -1) < filters.dateRange.from.toISODate()
  ) {
    return false;
  }

  if (
    filters.dateRange.to &&
    feature.properties?.['scan_date'] &&
    (feature.properties['scan_date'] as string).slice(0, -1) > filters.dateRange.to.toISODate()
  ) {
    return false;
  }

  // we filter width/ depth as an half-open interval
  // as in `x in [min, max[`
  // x is shown if `x === min` but not when `x === max`
  if (
    filters.width.min !== null &&
    isNumber(feature.properties?.['width']) &&
    Number(feature.properties?.['width']) * 100 < filters.width.min
  ) {
    return false;
  }

  if (
    filters.width.max !== null &&
    isNumber(feature.properties?.['width']) &&
    Number(feature.properties?.['width']) * 100 >= filters.width.max
  ) {
    return false;
  }

  if (
    filters.depth.min !== null &&
    isNumber(feature.properties?.['depth']) &&
    Number(feature.properties?.['depth']) * 100 < filters.depth.min
  ) {
    return false;
  }

  if (
    filters.depth.max !== null &&
    isNumber(feature.properties?.['depth']) &&
    Number(feature.properties?.['depth']) * 100 >= filters.depth.max
  ) {
    return false;
  }

  if (
    filters.scanDevices.length &&
    filters.scanDevices.includes(feature.properties?.['scan_device'])
  ) {
    return false;
  }

  if (
    filters.usageTypes.length &&
    filters.usageTypes.includes(feature.properties?.['usage_type'])
  ) {
    return false;
  }

  if (
    filters.layingTypes.length &&
    filters.layingTypes.includes(feature.properties?.['laying_type'])
  ) {
    return false;
  }

  if (
    filters.surfaceTypes.length &&
    filters.surfaceTypes.includes(feature.properties?.['surface_type'])
  ) {
    return false;
  }

  return true;
};

export const useFeaturesFiltered = ({ data, filters }: UseFeaturesFilteredProps) => {
  return useMemo(() => {
    if (!data) {
      return { features: null };
    }

    const features = data.features
      .filter((feature) => ['LineString', 'MultiLineString'].includes(feature.geometry.type))
      .filter((feature) => matchFilters({ feature, filters }));

    return { features };
  }, [data, filters]);
};
