import type { MapboxGeoJSONFeature } from 'mapbox-gl';
import { useCallback, useMemo } from 'react';

import type { FeatureSelection } from '@components/MapContextProvider';
import { useMapContext } from '@hooks/useMapContext';
import { type ClickEvent, useMapLayerInteraction } from '@hooks/useMapLayerInteraction';
import { isInZoomRange } from '@utils/isInZoomRange';
import { trackEvent } from '@utils/trackEvent';

export const useMapSelectFeatures = (
  layers: string[],
  uniqueByKey: string = 'id',
): FeatureSelection & { selectedFeature: MapboxGeoJSONFeature | null } => {
  const { featureSelection, setFeatureSelection } = useMapContext();

  const onClick = useCallback(
    (event: ClickEvent) => {
      // if the originalEvent is prevented return
      if (event.originalEvent.defaultPrevented) {
        return;
      }

      // here the original event is prevented
      // in this way the same mouse click event for another layer will get prevented if this comes first
      // for this to work, the order of the layers are important -> see MapSource.tsx
      event.originalEvent.preventDefault();

      const map = event.target;

      let features = event.features?.filter((f) => isInZoomRange(map, f.layer));

      if (!features || features.length === 0) {
        return;
      }

      // make unique
      features = [
        ...new Map(
          features.map<[string, MapboxGeoJSONFeature]>((feature) => [
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            feature[uniqueByKey],
            feature,
          ]),
        ).values(),
      ];
      const position = event.lngLat;

      setFeatureSelection({
        selectedFeatures: features,
        location: position,
        isInitialSelection: false,
      });

      // track event
      const firstFeature = features.length ? features[0] : null;

      trackEvent('mapSelectFeature', {
        location: { lat: position.lat, lng: position.lng },
        feature: firstFeature
          ? {
              id: firstFeature.id,
              sourceLayer: firstFeature.sourceLayer,
              properties: firstFeature.properties,
            }
          : null,
      });
    },
    [setFeatureSelection, uniqueByKey],
  );

  useMapLayerInteraction('click', layers, onClick);

  // only return features from the given layers
  // it just looks at the first feature
  // and returns all if given layers include the layer from this feature
  return useMemo(() => {
    if (
      featureSelection.selectedFeatures?.length &&
      layers.includes(featureSelection.selectedFeatures[0].layer.id)
    ) {
      return { ...featureSelection, selectedFeature: featureSelection.selectedFeatures[0] };
    }

    return {
      selectedFeature: null,
      selectedFeatures: null,
      location: null,
      isInitialSelection: undefined,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureSelection, JSON.stringify(layers)]);
};
