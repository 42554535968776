import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, type AccordionControlProps } from '@components/Accordion';
import { Table, TableCellText, TableRow } from '@components/Table';
import type { PhotoInfo } from '@hooks/useFetchPhotoInfo';

export type PhotoInfoCardProps = AccordionControlProps & {
  info?: PhotoInfo;
  children?: ReactNode;
};

export const PhotoInfoCard = ({
  expanded,
  setExpanded,
  collapsable,
  info,
  children,
}: PhotoInfoCardProps) => {
  const { t } = useTranslation();

  return (
    <Accordion
      collapsable={collapsable}
      expanded={expanded}
      setExpanded={setExpanded}
      title={t('components.photoInfoCard.title')}
    >
      {!info && (
        <Table>
          <TableRow>
            <TableCellText showBorder={!collapsable}>
              {t('components.photoInfoCard.info.noData')}
            </TableCellText>
            <TableCellText showBorder={!collapsable}></TableCellText>
          </TableRow>
        </Table>
      )}
      {info && (
        <Table>
          <TableRow>
            <TableCellText>{t('components.photoInfoCard.info.id')}</TableCellText>
            <TableCellText>{info.id}</TableCellText>
          </TableRow>
          {info.address && (
            <TableRow>
              <TableCellText>{t('components.photoInfoCard.info.address')}</TableCellText>
              <TableCellText>{info.address}</TableCellText>
            </TableRow>
          )}
          {info.category && (
            <TableRow>
              <TableCellText>{t('components.photoInfoCard.info.category')}</TableCellText>
              <TableCellText>{info.category}</TableCellText>
            </TableRow>
          )}
          <TableRow>
            <TableCellText>{t('components.photoInfoCard.info.date')}</TableCellText>
            <TableCellText>{info.date}</TableCellText>
          </TableRow>
          <TableRow>
            <TableCellText>{t('components.photoInfoCard.info.precision')}</TableCellText>
            <TableCellText>{info.solution}</TableCellText>
          </TableRow>
          <TableRow>
            <TableCellText showBorder={!!info.text || !collapsable}>
              {t('components.photoInfoCard.info.device')}
            </TableCellText>
            <TableCellText showBorder={!!info.text || !collapsable}>{info.device}</TableCellText>
          </TableRow>
          {info.text && (
            <TableRow>
              <TableCellText showBorder={!collapsable}>
                {t('components.photoInfoCard.info.comment')}
              </TableCellText>
              <TableCellText showBorder={!collapsable}>{info.text}</TableCellText>
            </TableRow>
          )}
        </Table>
      )}
      {children}
    </Accordion>
  );
};
