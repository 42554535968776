import type { ReactNode } from 'react';
import { Popup } from 'react-map-gl';

export const MapPopup = ({ children }: { children: ReactNode }) => {
  return (
    <Popup
      anchor="top"
      latitude={90 /* north pole. initial pose must be far off */}
      longitude={0}
      offset={16}
      onOpen={(event) => {
        // we need the timeout because https://github.com/mapbox/mapbox-gl-js/issues/9693
        setTimeout(() => {
          event.target.trackPointer();
        }, 0);
      }}
    >
      {children}
    </Popup>
  );
};
