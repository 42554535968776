import {
  Typography,
  Table as MuiTable,
  TableCell as MuiTableCell,
  type TableCellProps as MuiTableCellProps,
  TableRow,
  TableBody,
} from '@mui/material';
import type { ReactNode } from 'react';

export { TableRow };

export const Table = ({ children }: { children: ReactNode }) => {
  return (
    <MuiTable
      sx={{
        width: '100%',
      }}
    >
      <TableBody>{children}</TableBody>
    </MuiTable>
  );
};

// TODO why this additional Facade which basically does nothing but style the table slightly while not exposing the sx prop? We should rather style the table in the theme!
export const TableCell = ({
  children,
  showBorder = true,
  ...props
}: Omit<MuiTableCellProps, 'sx'> & { showBorder?: boolean }) => {
  return (
    <MuiTableCell
      sx={(theme) => ({
        // TODO: border color should come from theme (too dark)
        //  -> talk to team platform
        borderBottom: showBorder ? `1px solid ${theme.palette.divider}` : 'none',
      })}
      {...props}
    >
      {children}
    </MuiTableCell>
  );
};

export const TableCellText = ({
  children,
  showBorder = true,
}: {
  children?: ReactNode;
  showBorder?: boolean;
}) => {
  return (
    <TableCell showBorder={showBorder}>
      <Typography
        sx={
          {
            // ellipsis does not work with auto table layout
            // you can set "table-layout: fixed" to table
            // but then you would need a width for the columns
            // overflow: 'hidden',
            // textOverflow: 'ellipsis',
          }
        }
        variant="body2"
      >
        {children}
      </Typography>
    </TableCell>
  );
};
