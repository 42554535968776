import { useQuery } from '@tanstack/react-query';
import type { FeatureCollection } from 'geojson';

import { FetchError } from '@utils/FetchError';
import { getEnvironment } from '@utils/getEnvironment';

export type FilterBilling = {
  projectId: string;
};

const get = async ({ projectId }: FilterBilling) => {
  const { wfsSource } = getEnvironment();

  const cql = [];

  if (projectId) {
    cql.push(`project_id='${projectId}'`);
  }

  const cqlFilterString = cql.join(' AND ');

  if (!cqlFilterString) {
    throw new FetchError();
  }

  const response = await fetch(
    `${wfsSource}?request=GetFeature&typeName=trass_segmented&outputFormat=application/json&cql_filter=${cqlFilterString}`,
    {
      headers: {
        'x-api-key': '9ac2b310-d04e-4161-8f27-a112a63ff689',
      },
    },
  );

  const responseJson = await response.json();

  if (response.ok) {
    return responseJson;
  }

  if (!response.ok) {
    throw new FetchError(response, responseJson);
  }
};

export const useApiWfs = (filter: FilterBilling) => {
  return useQuery<FeatureCollection, FetchError | Error>({
    queryKey: ['useApiWfs', filter],
    queryFn: () => get(filter),
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  });
};
