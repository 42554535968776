import { useEffect, useState } from 'react';
import { type LngLat, useMap } from 'react-map-gl';

export const useMapView = () => {
  const { current: map } = useMap();

  const [zoom, setZoom] = useState<number>();
  const [center, setCenter] = useState<LngLat>();

  useEffect(() => {
    if (!map) return;

    const moveListener = () => {
      setCenter(map.getCenter());
      setZoom(map.getZoom());
    };

    // initial state
    setCenter(map.getCenter());
    setZoom(map.getZoom());

    // attach listener
    map.on('move', moveListener);

    return () => {
      // remove listener
      map.off('move', moveListener);
    };
  }, [map]);

  return {
    zoom,
    center,
  };
};
