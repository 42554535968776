import { Link } from '@mui/material';
import { styled } from '@mui/system';

// Create a styled Link component
export const TextLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  textDecoration: 'none',
}));
