import { useAuth0 } from '@auth0/auth0-react';
import { LoadingScreen } from '@deepup/loading-screen';
import type { ReactNode } from 'react';

import { ErrorPage } from '@components/ErrorPage';

export type AuthProps = {
  children: ReactNode;
};

export const Auth = ({ children }: AuthProps) => {
  const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated && error) {
    return <ErrorPage errorText={error?.message ?? 'Authentifizierung fehlgeschlagen'} />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: window.location.href } }).catch((e) =>
      console.error(e),
    );

    return <LoadingScreen />;
  }

  return children;
};
