import type { MapboxGeoJSONFeature } from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectBoxMultiReversed } from '@components/FilterBox/fields/SelectBoxMulti';
import type { FilterValues } from '@hooks/useFilters';
import { useMapZoomIsAbove } from '@hooks/useMapZoomIsAbove';
import { projectCloseupThreshold } from '@utils/mapboxExpressions';

export type ScanDeviceSelectProps = {
  scanDevices: FilterValues['scanDevices'];
  setScanDevices: FilterValues['setScanDevices'];
  features: MapboxGeoJSONFeature[];
  hasHelperText?: boolean;
  disabled?: boolean;
};

export const ScanDeviceSelect = ({
  scanDevices,
  setScanDevices,
  features,
  hasHelperText = true,
  disabled,
}: ScanDeviceSelectProps) => {
  const { t } = useTranslation();
  const [scanDevicesAvailable, setScanDevicesAvailable] = useState<string[]>([]);

  useEffect(() => {
    const scanDevicesUnique = [
      ...new Set([
        // add devices from get param to the list
        ...scanDevices,
        ...features
          .filter(
            (feature) => feature.properties?.scan_device_name || feature.properties?.scan_device,
          )
          .map(
            (feature) => feature.properties?.scan_device_name ?? feature.properties?.scan_device,
          ),
      ]),
    ].sort(Intl.Collator().compare);

    setScanDevicesAvailable(scanDevicesUnique);
    // JSON.stringify(scanDevices) prevents an infinity loop
    // maybe we have to wrap all filter values with useMemo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features, JSON.stringify(scanDevices)]);

  const { isZoomAboveThreshold } = useMapZoomIsAbove(projectCloseupThreshold);
  const [helperText, setHelperText] = useState<string>();
  const time = useRef<number>();

  useEffect(() => {
    window.clearTimeout(time.current);
    if (!isZoomAboveThreshold) {
      setHelperText(undefined);

      return;
    }

    // defer setter, so text is shown 1.5s after state change
    time.current = window.setTimeout(() => {
      if (!scanDevicesAvailable.length && isZoomAboveThreshold) {
        setHelperText('Keine Auswahl möglich, da kein Scan im Kartenausschnitt ist.');
      } else {
        setHelperText(undefined);
      }
    }, 1500);

    return () => {
      window.clearTimeout(time.current);
    };
  }, [isZoomAboveThreshold, scanDevicesAvailable.length]);

  return (
    <SelectBoxMultiReversed
      availableItems={scanDevicesAvailable}
      disabled={disabled || !scanDevicesAvailable.length}
      helperText={hasHelperText ? helperText : undefined}
      items={scanDevices}
      label={t('components.filterBox.scanDeviceSelect.label')}
      setItems={setScanDevices}
    />
  );
};
