import { Box, Slider, Stack, Typography } from '@mui/material';

type Range = {
  min: number | null;
  max: number | null;
};

type RangeSliderProps = {
  allowedRange: { min: number; max: number };
  range: Range;
  setRange: (range: Range) => void;
  label: string;
  disabled?: boolean;
};

export const RangeSlider = ({
  allowedRange,
  range,
  setRange,
  label,
  disabled,
}: RangeSliderProps) => {
  const handleSliderChange = (value: number | number[]) => {
    if (!Array.isArray(value) || value.length !== 2) {
      return;
    }

    // don't allow same value
    if (value[1] === value[0]) return;

    setRange({ min: value[0], max: value[1] });
  };

  return (
    <Stack direction="column">
      <Typography>{label}</Typography>
      <Slider
        color="secondary"
        disableSwap
        disabled={disabled}
        getAriaLabel={() => label}
        getAriaValueText={(v) => `${v}cm`}
        marks
        max={allowedRange.max}
        min={allowedRange.min}
        onChange={(_, newValue) => handleSliderChange(newValue)}
        size="small"
        step={5}
        value={[range.min ?? allowedRange.min, range.max ?? allowedRange.max]}
        valueLabelDisplay="auto"
      />
      <Stack direction="row">
        <Typography color="secondary" variant="body2">
          {allowedRange.min}cm
        </Typography>
        <Box component="div" sx={{ width: '100%' }} />
        <Typography color="secondary" variant="body2">
          {allowedRange.max}cm
        </Typography>
      </Stack>
    </Stack>
  );
};
