import type { Auth0ContextInterface } from '@auth0/auth0-react';

import { getEnvironment } from '@utils/getEnvironment';

import type { ImgProxyExtension, ImgProxyOptions } from './types';

export const optionsToUrlParam = (options: ImgProxyOptions) => {
  return Object.entries(options)
    .reduce<string[]>((acc, [key, option]) => {
      if (typeof option === 'undefined') {
        return acc;
      }

      return [
        ...acc,
        `${key.replace(/([A-Z])/g, '_$1').toLowerCase()}:${
          Array.isArray(option) ? option.flat(Infinity).join(':') : option
        }`,
      ];
    }, [])
    .join(',');
};

export const getImgProxySearchString = ({
  options,
  extension,
}: {
  options: ImgProxyOptions;
  extension?: ImgProxyExtension;
}) => {
  const searchParams = new URLSearchParams();

  if (Object.keys(options).length !== 0) {
    searchParams.append('options', optionsToUrlParam(options));
  }

  if (extension) {
    searchParams.append('extension', extension);
  }

  return searchParams.toString();
};

export const fetchImgProxy = async ({
  id,
  searchString,
  auth0,
}: {
  id: string;
  searchString: string;
  auth0: Auth0ContextInterface;
}) => {
  const { apiTrenchviewer } = getEnvironment();

  const token = await auth0.getAccessTokenSilently();

  return fetch(`${apiTrenchviewer}photos/${id}${searchString && `?${searchString}`}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
