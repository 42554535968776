import { Box, Divider, Typography } from '@mui/material';
import type { ReactNode } from 'react';

export type SidebarHeaderProps = {
  children: ReactNode;
};

export type SidebarHeaderTextProps = {
  children: ReactNode;
};

export const SidebarHeader = ({ children }: SidebarHeaderProps) => {
  return (
    <>
      <Box
        component="div"
        sx={{ minHeight: '56px', display: 'flex', alignItems: 'center', padding: '0 16px' }}
      >
        {children}
      </Box>
      <Divider />
    </>
  );
};

export const SidebarHeaderText = ({ children }: SidebarHeaderTextProps) => {
  return (
    <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
      {children}
    </Typography>
  );
};
