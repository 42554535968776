import { type Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { FetchError } from '@utils/FetchError';
import { getEnvironment } from '@utils/getEnvironment';

export type ProjectEntity = {
  id: string;
  name: string;
};

type ProjectEnvelope = { data: ProjectEntity[] };
const isProjectEntity = (entity: Partial<ProjectEntity>): entity is ProjectEntity =>
  typeof entity?.id === 'string' && typeof entity?.name === 'string';

const convertToProjectEnvelope = (envelope: Partial<ProjectEnvelope>) => {
  if (!Array.isArray(envelope?.data)) return undefined;
  if (!envelope.data.every(isProjectEntity)) return undefined;

  return envelope as ProjectEnvelope;
};

const fetchProjects = async (auth: Auth0ContextInterface): Promise<ProjectEnvelope> => {
  const { apiConMon } = getEnvironment();
  const token = await auth.getAccessTokenSilently();

  const response = await fetch(`${apiConMon}projects`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const responseJson = await response.json();
  const projectEnvelope = convertToProjectEnvelope(responseJson);

  if (!response.ok || !projectEnvelope) {
    throw new FetchError(response, responseJson);
  }

  return projectEnvelope;
};

export const useApiProjects = () => {
  const auth0 = useAuth0();

  return useQuery<ProjectEnvelope, FetchError | Error>({
    queryKey: ['useFetchProjects'],
    queryFn: () => fetchProjects(auth0),
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 0,
  });
};
