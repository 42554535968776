import { useEffect } from 'react';
import { Layer } from 'react-map-gl';
import { useParams } from 'react-router-dom';

import type { VectorLayerProps } from '@components/MapSource/VectorLayerProps';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useFilters } from '@hooks/useFilters';
import { useMapFeatureState } from '@hooks/useMapFeatureState';
import { useMapHoverFeature } from '@hooks/useMapHoverFeature';
import { useMapSelectFeatures } from '@hooks/useMapSelectFeatures';
import { useNavigateExtended } from '@hooks/useNavigateExtended';
import {
  filterTrassSegmentExpression,
  isHoveredExpression,
  isTrassExpression,
  projectCloseupThreshold,
  trassUsageColorExpression,
} from '@utils/mapboxExpressions';

export const Trasses = ({ source, sourceLayer }: VectorLayerProps) => {
  const { segmentId } = useParams();
  const { feature: hoveredFeature } = useMapHoverFeature('trass-layer');
  const { selectedFeature } = useMapSelectFeatures(['trass-layer']);
  const navigate = useNavigateExtended();
  const filters = useFilters();
  const filter = filterTrassSegmentExpression(filters);
  const { isTrassesEnabled } = useFeatureFlags();

  useEffect(() => {
    const trassSegmentId = selectedFeature?.properties?.fid;

    if (trassSegmentId) {
      navigate(`segment/${trassSegmentId}`, { preserveSearch: true, preserveHash: true });
    }
    // https://github.com/remix-run/react-router/issues/7634
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);

  // TODO why are these hooks separate from useMapHoverFeature and useMapSelectFeatures?
  useMapFeatureState(hoveredFeature, { hover: true });

  if (!isTrassesEnabled || !filters.showTrasses) {
    return;
  }

  return (
    <Layer
      filter={filter}
      id="trass-layer"
      layout={{ 'line-cap': 'round' }}
      minzoom={projectCloseupThreshold}
      paint={{
        'line-color': [
          'case',
          ['any', isTrassExpression(segmentId), isHoveredExpression],
          '#cdcdcd',
          trassUsageColorExpression(filters.activeTrassFilter),
        ],
        'line-width': 4,
      }}
      source={source}
      source-layer={sourceLayer}
      type="line"
    />
  );
};
