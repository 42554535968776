import type { photo as photoApi } from '@deepup/apis';
import { useState } from 'react';

import { Sidebar } from '@components/Sidebar';

import { PhotoWithInfo } from './PhotoInfo';

type Props = {
  photo: photoApi.Photo;
  setPhoto: (photo: photoApi.Photo | null) => void;
};

export const PhotoSidebar = ({ photo, setPhoto }: Props) => {
  const [sidebar, setSidebar] = useState<null | 'fullscreen'>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Sidebar
      key={photo?.id}
      onClose={() => {
        setPhoto(null);
        setSidebar(null);
        setExpanded(false);
      }}
      setSidebar={setSidebar}
      sidebar={sidebar}
    >
      {photo && (
        <PhotoWithInfo
          expanded={expanded}
          isFullscreen={sidebar === 'fullscreen'}
          photo={photo}
          setExpanded={setExpanded}
        />
      )}
    </Sidebar>
  );
};
