import { debounce } from '@mui/material';
import { type RefObject, useEffect, useRef } from 'react';

export const useResizeObserver = (
  ref: RefObject<Element>,
  onResize: () => void,
  debounceDelay = 150,
) => {
  const lastSize = useRef('undefined');

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const currentRef = ref.current;

    const resizeObserver = new ResizeObserver(
      debounce((entries) => {
        const contentBoxSize = entries[0]?.contentBoxSize[0];
        const hasSize = contentBoxSize?.inlineSize || contentBoxSize?.blockSize;
        const size = `${contentBoxSize?.inlineSize}x${contentBoxSize?.blockSize}`;

        // only trigger onResize if size is different from last size
        // and if it has a size (display: none will trigger size 0x0)
        if (hasSize && size !== lastSize.current) {
          onResize();
          lastSize.current = size;
        }
      }, debounceDelay),
    );

    resizeObserver.observe(currentRef);

    return () => {
      resizeObserver.unobserve(currentRef);
    };
  }, [debounceDelay, onResize, ref]);
};
