import { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { useApiWfs } from '@hooks/useApiWfs';
import type { FilterValues } from '@hooks/useFilters';
import { filterTrassSegmentBillingExpression } from '@utils/mapboxExpressions';

export type MapSourceWfsProps = {
  data: ReturnType<typeof useApiWfs>['data'];
  filters: FilterValues;
};

export const MapSourceWfs = ({ data, filters }: MapSourceWfsProps) => {
  const filterExpression = useMemo(() => filterTrassSegmentBillingExpression(filters), [filters]);

  if (!data) {
    return null;
  }

  return (
    <>
      <Source data={data} id="trass-source" promoteId={'fid'} type="geojson" />

      <Layer
        filter={filterExpression}
        id="trass-layer"
        layout={{ 'line-cap': 'round' }}
        paint={{
          'line-color': '#ff0000',
          'line-width': 3,
        }}
        source="trass-source"
        type="line"
      />
    </>
  );
};
