import turfLength from '@turf/length';
import type { Feature } from 'geojson';
import { useMemo } from 'react';

export type UseFeaturesLengthProps = {
  data?: { features: Feature[] | null };
};

export const useFeaturesLength = ({ data }: UseFeaturesLengthProps) => {
  return useMemo(() => {
    if (!data || !data.features) {
      return null;
    }

    return data.features.reduce((acc, feature) => {
      const length = turfLength(feature, { units: 'kilometers' });

      return acc + length;
    }, 0);
  }, [data]);
};
