type Unit = Intl.NumberFormatOptions['unit'];
type Locale = 'de-DE' | 'en-GB';
/**
 * Formats a number as a unit.
 * Pass any unit from this list https://github.com/unicode-org/cldr/blob/main/common/validity/unit.xml
 */
export const formatAsUnit = (
  number: number,
  unit: Unit,
  locale: Locale = 'de-DE', // TODO: remove default value for translation lib
) =>
  Intl.NumberFormat(locale, {
    style: 'unit',
    unit,
    unitDisplay: 'short',
  }).format(number);
