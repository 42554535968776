import { ArrowsMaximize4, ArrowsMinimize4, XOutline } from '@deepup/icons';
import { Box, IconButton } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { type ReactNode, useState } from 'react';

import { SidebarHeader } from '@components/Sidebar';
import { useConfig } from '@hooks/useConfig';
import type { FilterValues } from '@hooks/useFilters';
import { useThemeMode } from '@hooks/useThemeMode';

export type SidebarProps = {
  children: ReactNode;
  onClose?: () => void;
  drawerWidth?: number | string;
  sidebar: FilterValues['sidebar'];
  setSidebar: FilterValues['setSidebar'];
};

export const Sidebar = ({
  children,
  onClose,
  drawerWidth = '29%',
  sidebar,
  setSidebar,
}: SidebarProps) => {
  const [open, setOpen] = useState(true);
  const { isDarkMode } = useThemeMode();
  const { drawerStyle } = useConfig();

  const onFullscreen = () => {
    setSidebar((prevState) => (!prevState ? 'fullscreen' : null));
  };

  const isFullscreen = sidebar === 'fullscreen';

  return (
    <SwipeableDrawer
      ModalProps={{ disableEnforceFocus: true }}
      anchor="right"
      hideBackdrop
      onClose={() => {
        setOpen(false);
        onClose?.();
      }}
      onOpen={() => setOpen(true)}
      open={open}
      sx={{
        width: isFullscreen ? '100%' : drawerWidth,
        left: 'auto',
        [`& .MuiDrawer-paper`]: {
          width: isFullscreen ? '100%' : drawerWidth,
          boxSizing: 'border-box',
          ...drawerStyle,
        },
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <SidebarHeader>
          <Box component="div" sx={{ flex: 1 }}>
            <IconButton onClick={onClose} size="small" sx={{ fontSize: '22px' }}>
              <XOutline fill={isDarkMode ? '#ccc' : undefined} />
            </IconButton>
          </Box>
          <IconButton onClick={onFullscreen} size="small" sx={{ fontSize: '18px' }}>
            {isFullscreen ? (
              <ArrowsMinimize4 fill={isDarkMode ? '#ccc' : undefined} />
            ) : (
              <ArrowsMaximize4 fill={isDarkMode ? '#ccc' : undefined} />
            )}
          </IconButton>
        </SidebarHeader>
        <Box component="div" sx={{ flex: 1, overflowY: 'auto', padding: '0' }}>
          {children}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
