import { CubeOutline, PhotoOutline } from '@deepup/icons';
import { Box, Typography } from '@mui/material';
import { type Dispatch, type ReactNode, type SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ErrorBoundary } from '@components/ErrorBoundary';
import { MeshViewer } from '@components/MeshViewer';
import { type ScanInfo, ScanInfoCard } from '@components/ScanInfoCard';
import { Sidebar } from '@components/Sidebar';
import { Tabs } from '@components/Tabs';
import { TopDownViewer } from '@components/TopDownViewer';
import { useFetchScanInfo } from '@hooks/useFetchScanInfo';
import { useFilters } from '@hooks/useFilters';
import { useNavigateExtended } from '@hooks/useNavigateExtended';
import { trackEvent } from '@utils/trackEvent';

// save possible tabs for converting to/from index
const tabs = ['top-down-view', 'hd-mesh'];

export const Page = () => {
  const { t } = useTranslation();
  const { scanId, tab } = useParams();
  const navigate = useNavigateExtended();
  const { sidebar, setSidebar } = useFilters();
  const isFullscreen = sidebar === 'fullscreen';

  const { scanInfo } = useFetchScanInfo(scanId);

  const onSidebarClose = () => {
    navigate(`../../../..`, {
      relative: 'path',
      preserveHash: true,
      preserveSearch: true,
      preserveSearchExclude: ['sidebar'],
    });
    trackEvent('sidebarClosed');
  };

  const onTabsChange = (index: number) => {
    navigate(`../${tabs[index]}`, { relative: 'path', preserveHash: true, preserveSearch: true });
  };

  const currentIndex = useMemo(() => {
    return tab ? Math.max(tabs.indexOf(tab), 0) : 0;
  }, [tab]);

  const [isInfoExpanded, setIsInfoExpanded] = useState(isFullscreen);

  return (
    <>
      {scanId && tab && (
        <Sidebar key={scanId} onClose={onSidebarClose} setSidebar={setSidebar} sidebar={sidebar}>
          <Tabs
            index={currentIndex}
            items={[
              {
                label: (
                  <Box
                    component="div"
                    sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}
                  >
                    <PhotoOutline fill="currentColor" height="1.4em" width="1.4em" />
                    <Typography>{t('pages.map.scan.2dView')}</Typography>
                  </Box>
                ),
                children: (
                  <ScanTabItem
                    fallbackText={t('pages.map.scan.loadError2dView')}
                    isFullscreen={isFullscreen}
                    isInfoExpanded={isInfoExpanded}
                    scanId={scanId}
                    scanInfo={scanInfo}
                    setIsInfoExpanded={setIsInfoExpanded}
                  >
                    <TopDownViewer scanId={scanId} />
                  </ScanTabItem>
                ),
              },
              {
                label: (
                  <>
                    <Box
                      component="div"
                      sx={{ display: 'flex', gap: '15px', alignItems: 'flex-end' }}
                    >
                      <CubeOutline fill="currentColor" height="1.4em" width="1.4em" />
                      <Typography>{t('pages.map.scan.3dView')}</Typography>
                    </Box>
                  </>
                ),
                children: (
                  <ScanTabItem
                    fallbackText={t('pages.map.scan.loadError3dView')}
                    isFullscreen={isFullscreen}
                    isInfoExpanded={isInfoExpanded}
                    scanId={scanId}
                    scanInfo={scanInfo}
                    setIsInfoExpanded={setIsInfoExpanded}
                  >
                    <MeshViewer scanId={scanId} />
                  </ScanTabItem>
                ),
              },
            ]}
            key={scanId}
            onChange={onTabsChange}
          />
        </Sidebar>
      )}
    </>
  );
};

const ScanTabItem = ({
  scanId,
  scanInfo,
  children,
  fallbackText,
  isFullscreen,
  isInfoExpanded,
  setIsInfoExpanded,
}: {
  scanId: string;
  scanInfo?: ScanInfo;
  children: ReactNode;
  fallbackText: string;
  isFullscreen: boolean;
  isInfoExpanded: boolean;
  setIsInfoExpanded: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: isFullscreen ? 'row' : 'column',
        padding: isFullscreen ? 0 : '16px',
        overflow: 'hidden',
      }}
    >
      <Box
        component="div"
        sx={{
          flex: 1,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          borderRadius: isFullscreen ? 0 : '10px',
        }}
      >
        <ErrorBoundary fallback={<Typography>{fallbackText}</Typography>}>{children}</ErrorBoundary>
      </Box>
      <Box component="div" sx={{ width: isFullscreen ? 'min(30%, 400px)' : '100%' }}>
        <ScanInfoCard
          collapsable={!isFullscreen}
          expanded={isInfoExpanded}
          info={scanInfo}
          scanId={scanId}
          setExpanded={setIsInfoExpanded}
        />
      </Box>
    </Box>
  );
};
