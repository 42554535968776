import { type Layer, type Map } from 'mapbox-gl';

export const isInZoomRange = (
  map: Pick<Map, 'getZoom' | 'getMinZoom' | 'getMaxZoom'>,
  layer: Pick<Layer, 'minzoom' | 'maxzoom'>,
) => {
  const zoom = map.getZoom();
  const maxZoom = layer.maxzoom ?? map.getMaxZoom();
  const minZoom = layer.minzoom ?? map.getMinZoom();

  return zoom >= minZoom && zoom <= maxZoom;
};
