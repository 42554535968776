import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { App } from '@components/App';
import { getEnvironment } from '@utils/getEnvironment';

import './i18n.config';

if (getEnvironment().isMockingEnabled) {
  import('@mocks/browser');
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
