import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DateTime } from 'luxon';
import { type Dispatch, type MouseEvent, type SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type DateRange = { from: DateTime<true> | null; to: DateTime<true> | null };

export type DateButtonsProps = {
  dateRange: DateRange;
  setDateRange: Dispatch<SetStateAction<DateRange>>;
  disabled?: boolean;
};

export const DateButtons = ({ dateRange, setDateRange, disabled }: DateButtonsProps) => {
  const { t } = useTranslation();
  const quickFilters: { [key: string]: { from: DateTime; to: DateTime } } = useMemo(
    () => ({
      today: {
        from: DateTime.now().startOf('day').set({ millisecond: 0 }),
        to: DateTime.now().endOf('day').set({ millisecond: 0 }),
      },
      yesterday: {
        from: DateTime.now().minus({ days: 1 }).startOf('day').set({ millisecond: 0 }),
        to: DateTime.now().minus({ days: 1 }).endOf('day').set({ millisecond: 0 }),
      },
      lastWeek: {
        from: DateTime.now().minus({ days: 7 }).startOf('day').set({ millisecond: 0 }),
        to: DateTime.now().endOf('day').set({ millisecond: 0 }),
      },
    }),
    [],
  );

  const onChange = (_event: MouseEvent, value: string) => {
    if (!value) {
      setDateRange({ from: null, to: null });

      return;
    }

    const quickFilter = quickFilters[value];

    if (quickFilter) {
      setDateRange({ from: quickFilter.from, to: quickFilter.to });
    }
  };

  const value = useMemo(
    () =>
      Object.keys(quickFilters).find((quickFilter) => {
        // every quickfilter has a from date
        if (!dateRange.from) return false;

        // 'today' and 'last week' can have empty 'to' dates
        const to = dateRange.to ?? DateTime.now();

        return (
          quickFilters[quickFilter].from.equals(
            dateRange.from.startOf('day').set({ millisecond: 0 }),
          ) && quickFilters[quickFilter].to.equals(to.endOf('day').set({ millisecond: 0 }))
        );
      }),
    [dateRange.from, dateRange.to, quickFilters],
  );

  return (
    <ToggleButtonGroup disabled={disabled} exclusive fullWidth onChange={onChange} value={value}>
      <ToggleButton sx={{ flex: 1 }} value="today">
        {t('components.filterBox.fields.dateButtons.today')}
      </ToggleButton>
      <ToggleButton sx={{ flex: 1 }} value="yesterday">
        {t('components.filterBox.fields.dateButtons.yesterday')}
      </ToggleButton>
      <ToggleButton sx={{ flex: 2 }} value="lastWeek">
        {t('components.filterBox.fields.dateButtons.7Days')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
