import { MapProvider } from 'react-map-gl';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  return (
    <MapProvider>
      <Outlet />
    </MapProvider>
  );
};
