import { User } from '@auth0/auth0-react';
import flagsmith from 'flagsmith';
import { useEffect } from 'react';

export const useFeatureFlagIdentity = (user?: User) => {
  useEffect(() => {
    const fun = async () => {
      const userDomain = user?.email?.split('@')[1]?.toLowerCase();

      if (!userDomain) return;
      const isDeepUp = userDomain.startsWith('deepup') ?? false;

      await flagsmith.identify(userDomain, { role: isDeepUp ? 'deepup' : 'customer' });
    };

    fun();
  }, [user]);
};
