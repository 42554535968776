import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectBoxMultiReversed } from '@components/FilterBox/fields/SelectBoxMulti';
import type { FilterValues } from '@hooks/useFilters';
import { useMapZoomIsAbove } from '@hooks/useMapZoomIsAbove';
import { projectCloseupThreshold } from '@utils/mapboxExpressions';

export type PhotoCategorySelectProps = {
  photoCategories: FilterValues['photoCategories'];
  setPhotoCategories: FilterValues['setPhotoCategories'];
  availablePhotoCategories: FilterValues['photoCategories'];
  hasHelperText?: boolean;
  disabled?: boolean;
};

export const PhotoCategorySelect = ({
  photoCategories,
  setPhotoCategories,
  availablePhotoCategories,
  hasHelperText = true,
  disabled,
}: PhotoCategorySelectProps) => {
  const { isZoomAboveThreshold } = useMapZoomIsAbove(projectCloseupThreshold);
  const [helperText, setHelperText] = useState<string>();
  const time = useRef<number>();
  const { t } = useTranslation();

  useEffect(() => {
    window.clearTimeout(time.current);
    if (!isZoomAboveThreshold) {
      setHelperText(undefined);

      return;
    }

    // defer setter, so text is shown 1.5s after state change
    time.current = window.setTimeout(() => {
      if (!availablePhotoCategories.length && isZoomAboveThreshold) {
        setHelperText(t('components.filterBox.photoCategorySelect.helperText'));
      } else {
        setHelperText(undefined);
      }
    }, 1500);

    return () => {
      window.clearTimeout(time.current);
    };
  }, [isZoomAboveThreshold, availablePhotoCategories, t]);

  return (
    <SelectBoxMultiReversed
      availableItems={availablePhotoCategories}
      disabled={disabled || !availablePhotoCategories.length}
      helperText={hasHelperText ? helperText : undefined}
      items={photoCategories}
      label={t('components.filterBox.photoCategorySelect.label')}
      setItems={setPhotoCategories}
    />
  );
};
