import type { EventData, MapLayerEventType } from 'mapbox-gl';
import { useEffect } from 'react';
import { useMap } from 'react-map-gl';

export type ClickEvent = MapLayerEventType['click'] & EventData;
export type MouseMoveEvent = MapLayerEventType['mousemove'] & EventData;
export type MouseOutEvent = MapLayerEventType['mouseenter'] & EventData;

export function useMapLayerInteraction<T extends keyof MapLayerEventType>(
  type: T,
  layers: readonly string[] | string,
  onInteraction: (event: MapLayerEventType[T] & EventData) => void,
) {
  const { current: map } = useMap();

  useEffect(() => {
    if (!map) return;

    map.on(type, layers, onInteraction);

    return () => {
      map.off(type, layers, onInteraction);
    };
    // only add events one time on mount
    // in this way the order in which
    // the event handlers are registered will be preserved
    // else the preventDefault trick will not work
    // -> see useMapHoverFeature.ts and useMapSelectFeatures.ts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
