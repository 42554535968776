import type { Expression } from 'mapbox-gl';
import { useEffect, useState } from 'react';

import { useMapFeatures } from '@hooks/useMapFeatures';

const featureIdFilter = (featureId: number | undefined): Expression => [
  '==',
  ['get', 'id'],
  featureId ?? '',
];

export const useFetchFeatureInfo = (featureId: number) => {
  const features = useMapFeatures(
    {
      source: 'plandata-source',
      sourceLayer: 'trassen',
      filter: featureIdFilter(featureId),
    },
    {
      source: 'plandata-source',
      sourceLayer: 'apls',
      filter: featureIdFilter(featureId),
    },
    {
      source: 'plandata-source',
      sourceLayer: 'schaltpunkte',
      filter: featureIdFilter(featureId),
    },
  );

  const [featureInfo, setFeatureInfo] = useState<{
    id: null | number;
    [key: string]: null | string | number;
  } | null>(null);

  useEffect(() => {
    // don't unset featureInfo if featureId hasn't changed
    if (!featureId || !features?.[0] || featureInfo?.id === featureId) return;

    setFeatureInfo({
      // make sure an "id" is set and is first property
      id: features?.[0]?.properties?.id ? Number(features?.[0]?.properties?.id) : null,
      ...features?.[0]?.properties,
    });
  }, [featureId, featureInfo?.id, features]);

  return { featureInfo };
};
