import { useEffect } from 'react';
import { Layer } from 'react-map-gl';
import { useParams } from 'react-router-dom';

import { type VectorLayerProps } from '@components/MapSource/VectorLayerProps';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useFilters } from '@hooks/useFilters';
import { useMapFeatureState } from '@hooks/useMapFeatureState';
import { useMapHoverFeature } from '@hooks/useMapHoverFeature';
import { useMapSelectFeatures } from '@hooks/useMapSelectFeatures';
import { useNavigateExtended } from '@hooks/useNavigateExtended';
import { isFeatureExpression, isHoveredExpression } from '@utils/mapboxExpressions';

export const Plandata = ({ source }: VectorLayerProps) => {
  const { featureId } = useParams();
  const navigate = useNavigateExtended();
  const { showPlandata } = useFilters();
  const { selectedFeature } = useMapSelectFeatures([
    'plandata',
    'plandata-apl',
    'plandata-schaltpunkte',
  ]);
  const { isPlandataEnabled } = useFeatureFlags();

  const { feature: hoveredFeature1 } = useMapHoverFeature('plandata');
  const { feature: hoveredFeature2 } = useMapHoverFeature('plandata-apl');
  const { feature: hoveredFeature3 } = useMapHoverFeature('plandata-schaltpunkte');

  // add state to highlight feature
  useMapFeatureState(hoveredFeature1, { hover: true });
  useMapFeatureState(hoveredFeature2, { hover: true });
  useMapFeatureState(hoveredFeature3, { hover: true });

  useEffect(() => {
    const featureId = selectedFeature?.properties?.id;

    if (featureId) {
      navigate(`feature/${featureId}`, { preserveSearch: true, preserveHash: true });
    }
    // https://github.com/remix-run/react-router/issues/7634
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);

  if (!showPlandata || !isPlandataEnabled) {
    return null;
  }

  const isFeature = isFeatureExpression(featureId);

  return (
    <>
      <Layer
        id="plandata"
        layout={{
          'line-sort-key': 1,
          'line-join': 'round',
          'line-cap': 'round',
        }}
        paint={{
          'line-color': ['case', isFeature, '#005C49', '#00A887'],
          'line-width': ['case', isHoveredExpression, 6, isFeature, 6, 4],
          'line-opacity': 0.8,
        }}
        source={source}
        source-layer="trassen"
        type="line"
      />
      <Layer
        id="plandata-apl"
        paint={{
          'circle-color': '#0000ff',
          'circle-radius': ['case', isHoveredExpression, 6, isFeature, 6, 4],
          'circle-pitch-scale': 'map',
        }}
        source={source}
        source-layer="apls"
        type="circle"
      />

      <Layer
        id="plandata-schaltpunkte"
        paint={{
          'circle-color': '#ff0000',
          'circle-radius': ['case', isHoveredExpression, 6, isFeature, 6, 4],
          'circle-pitch-scale': 'map',
        }}
        source={source}
        source-layer="schaltpunkte"
        type="circle"
      />
    </>
  );
};
