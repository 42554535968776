/**
 * Checks if a value is defined.
 * Use e.g. in array.filter(isDefined) to filter out undefined values.
 */
export const isDefined = <T>(value: T | undefined): value is T => !!value;

/**
 * Casts a string to a number if possible. Otherwise, returns undefined.
 */
export const toNumber = (str?: string): number | undefined => {
  if (!str) return;
  const num = Number(str);

  return isNaN(num) ? undefined : num;
};

/**
 * Removes all duplicates from an array by a given property name.
 */
export const removeDuplicatesBy =
  <T>(propName: keyof T) =>
  (e: T, i: number, arr: T[]) =>
    arr.map((x) => x[propName]).lastIndexOf(e[propName]) === i;

export const removeDuplicates = <T>(e: T, i: number, arr: T[]): boolean => arr.lastIndexOf(e) === i;

const colors = [
  'RED',
  'GREEN',
  'BLUE',
  'YELLOW',
  'WHITE',
  'GREY',
  'BROWN',
  'VIOLET',
  'TURQUOISE',
  'BLACK',
  'ORANGE',
  'PINK',
  'TRANSPARENT',
  'UNKNOWN',
];

export type Color = (typeof colors)[number];

export const toColor = (value?: string): Color | undefined =>
  colors.includes(value as Color) ? value : undefined;

export const isEmpty = <T>(a: T[]) => a.length === 0;

export const isEqual = <T>(a: T[], b: T[], compareFn?: (a: T, b: T) => number): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  const sortedA = [...a].sort(compareFn); // toSorted doesn't work in WebStorm :(
  const sortedB = [...b].sort(compareFn); // https://stackoverflow.com/a/76300789

  return JSON.stringify(sortedA) === JSON.stringify(sortedB);
};

// removes item or adds, if not present yet
export const toggleInArray = <T>(array: T[], item: T): T[] => {
  const index = array.indexOf(item);

  if (index === -1) {
    return [...array, item];
  }

  return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const camelToKebab = (camelCaseStr: string) => {
  return camelCaseStr.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

// returns the number of selected items
// given the selected items and the deselected items which can include items from the selectedItems array
export const getNumberSelected = <T extends string>(selectedItems: T[], deselectedItems: T[]) => {
  if (!deselectedItems.length || !selectedItems.length) {
    return selectedItems.length;
  }

  return (
    selectedItems.length -
    deselectedItems.reduce((acc, item) => {
      return acc + (selectedItems.includes(item) ? 1 : 0);
    }, 0)
  );
};

export const getDifferenceQuantity = <T extends string>(a: T[], b: T[]) =>
  a.filter((item) => !b.includes(item));

export const distinct = <T>(array: T[]) => [...new Set(array)] as T[];
