import { comment as commentApi, photo as photoApi } from '@deepup/apis';
import { Paper, type Theme } from '@mui/material';
import { DataGrid, type GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { fromTimestamp, localizeTimestampApi } from '@utils/timeFormatting';

import { GeoPositionSolutionCell, ImageCell, MultilineText } from './TableCell';

type Props = {
  photos: photoApi.Photo[];
  setPhoto: (item: photoApi.Photo) => void;
};

export const PhotoTable = ({ photos, setPhoto }: Props) => {
  const { t } = useTranslation();
  const columns: GridColDef<photoApi.Photo>[] = [
    {
      disableColumnMenu: true,
      field: 'id',
      filterable: false,
      headerName: t('components.photoDownload.table.photo'),
      hideable: true,
      width: 100,
      sortable: false,
      type: 'string',
      renderCell: (params) => <ImageCell {...params.row} />,
    },
    {
      disableColumnMenu: true,
      field: 'address',
      filterable: false,
      headerName: t('components.photoDownload.table.address'),
      hideable: true,
      flex: 1.5,
      type: 'string',
      valueGetter: (_, row) => {
        if (row.location) {
          const { city, place } = row.location;

          return `${place} ${city}`;
        }

        return '';
      },
      renderCell: (params) => {
        if (params.row.location) {
          const { city, place } = params.row.location;

          return <MultilineText text={`${place} ${city}`} />;
        }
      },
      cellClassName: 'centered-cell',
    },
    {
      disableColumnMenu: true,
      field: 'comment-device',
      filterable: false,
      headerName: t('components.photoDownload.table.commentDevice'),
      hideable: false,
      flex: 1.2,
      type: 'string',
      valueGetter: (_, row) =>
        row.comments
          .filter((item) => item.type === commentApi.CommentType.DEVICE)
          .map((item) => item.text)
          .join(''),
      renderCell: (params) => {
        const text = params.row.comments
          .filter((item) => item.type === commentApi.CommentType.DEVICE)
          .map((item) => item.text)
          .join('\n');

        return <MultilineText text={text} />;
      },
      cellClassName: 'centered-cell',
    },
    {
      disableColumnMenu: true,
      field: 'comment-note',
      filterable: false,
      headerName: t('components.photoDownload.table.commentNote'),
      hideable: false,
      flex: 1,
      type: 'string',
      valueGetter: (_, row) =>
        row.comments
          .filter((item) => item.type === commentApi.CommentType.NOTE)
          .map((item) => item.text)
          .join(''),

      renderCell: (params) => {
        const text = params.row.comments
          .filter((item) => item.type === commentApi.CommentType.NOTE)
          .map((item) => item.text)
          .join('\n');

        return <MultilineText text={text} />;
      },
      cellClassName: 'centered-cell',
    },
    {
      disableColumnMenu: true,
      field: 'scanDeviceName',
      filterable: false,
      headerName: t('components.photoDownload.table.device'),
      hideable: false,
      flex: 1,
      valueGetter: (_, row) => row.deviceId,
      type: 'string',
      renderCell: (params) => <MultilineText text={params.row.deviceId} />,
      cellClassName: 'centered-cell',
    },
    {
      disableColumnMenu: true,
      field: 'category',
      filterable: false,
      headerName: t('components.photoDownload.table.category'),
      hideable: false,
      valueGetter: (_, row) => row.categoryName,
      flex: 1,
      maxWidth: 160,
      type: 'string',
      renderCell: (params) => <MultilineText text={params.row.categoryName} />,
      cellClassName: 'centered-cell',
    },
    {
      disableColumnMenu: true,
      field: 'geoPositionSolution',
      filterable: false,
      headerName: t('components.photoDownload.table.precision'),
      hideable: false,
      flex: 1,
      maxWidth: 80,
      type: 'string',
      renderCell: (params) => (
        <GeoPositionSolutionCell geoPositionSolution={params.row.geoPositionSolution} />
      ),
      cellClassName: 'centered-cell',
    },
    {
      disableColumnMenu: true,
      field: 'recordedDate',
      filterable: false,
      headerName: t('components.photoDownload.table.date'),
      hideable: false,
      flex: 1,
      maxWidth: 100,
      type: 'string',
      valueGetter: (_, row) => row.recordedAt && fromTimestamp(row.recordedAt),
      sortComparator: (dt1, dt2) => {
        if (dt1 < dt2) {
          return -1;
        } else if (dt1 > dt2) {
          return 1;
        } else {
          return 0;
        }
      },
      renderCell: (params) => {
        return localizeTimestampApi(params.row.recordedAt);
      },
    },
  ];

  return (
    <Paper elevation={1} sx={{ height: (theme: Theme) => `calc(100vh - ${theme.spacing(52)})` }}>
      <DataGrid
        columns={columns}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        initialState={{
          sorting: {
            sortModel: [{ field: 'recordedDate', sort: 'desc' }],
          },
        }}
        onRowClick={(params) => setPhoto(params.row)}
        rowHeight={72}
        rows={photos}
        sx={{
          '& .centered-cell': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      />
    </Paper>
  );
};
