import { styled } from '@mui/material';

type LoadingScreenProps = {
  isDarkMode?: boolean;
};

const LoadingContainer = styled('div')<{ $isDarkMode: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader-wrapper {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader {
    position: relative;
    width: 48px;
    height: 48px;
    background: #00a887;
    transform: rotateX(65deg) rotate(45deg);
    color: ${(props) => (props.$isDarkMode ? '#fff' : '#000')};
    animation: layers1 1s linear infinite alternate;
  }
  .loader:after {
    content: '';
    position: absolute;
    inset: 0;
    background: ${(props) =>
      props.$isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'};
    animation: layerTr 1s linear infinite alternate;
  }

  @keyframes layers1 {
    0% {
      box-shadow: 0 0 0 0;
    }
    90%,
    100% {
      box-shadow: 20px 20px 0 -4px;
    }
  }
  @keyframes layerTr {
    0% {
      transform: translate(0, 0) scale(1);
    }
    100% {
      transform: translate(-25px, -25px) scale(1);
    }
  }
`;

export const LoadingScreen = ({ isDarkMode = false }: LoadingScreenProps) => (
  <LoadingContainer $isDarkMode={isDarkMode}>
    <div className="loader-wrapper">
      <div className="loader" />
    </div>
  </LoadingContainer>
);
