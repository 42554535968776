import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';

import { LoadingSpinner } from '@components/ScanInfoCard';
import { useFetchFeatureInfo } from '@hooks/useFetchFeatureInfo';

export const FeatureInfoCard = ({ featureId }: { featureId: number }) => {
  const { featureInfo } = useFetchFeatureInfo(featureId);

  if (!featureInfo) {
    // TODO: stop spinner after 3-5 secs, if not found
    return <LoadingSpinner />;
  }

  return (
    <Box component="div" sx={(theme) => ({ padding: theme.spacing(2) })}>
      <Table size="small">
        <TableBody
          sx={{
            '& td': { border: 0 },
            '& td:nth-of-type(1)': { width: '120px' },
          }}
        >
          {Object.entries(featureInfo).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell style={{ verticalAlign: 'top' }}>{key === 'id' ? 'ID' : key}</TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
