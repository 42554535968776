import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

import {
  fetchImgProxy,
  getImgProxySearchString,
  type ImgProxyExtension,
  type ImgProxyOptions,
} from '@utils/imgProxy';

export type UseImageSourceProps = ImgProxyOptions & {
  id: string;
  options?: ImgProxyOptions;
  extension?: ImgProxyExtension;
};

export type ImageSourceUrl = string | null | Error;

export const useImageSource = ({
  id,
  options: optionsProp,
  extension,
  ...optionsDirectProp
}: UseImageSourceProps) => {
  const [imageSourceUrl, setImageSourceUrl] = useState<ImageSourceUrl>(null);
  const auth0 = useAuth0();

  const options = { ...optionsProp, ...optionsDirectProp };
  const searchString = getImgProxySearchString({ options, extension });

  useEffect(() => {
    const func = async () => {
      try {
        const response = await fetchImgProxy({ id, searchString, auth0 });

        if (response.ok) {
          const imageBlob = await response.blob();

          setImageSourceUrl(URL.createObjectURL(imageBlob));
        } else {
          setImageSourceUrl(new Error('Fehler'));
        }
      } catch (error) {
        setImageSourceUrl(new Error('Fehler'));
      }
    };

    func();

    return () => {
      if (typeof imageSourceUrl === 'string') {
        URL.revokeObjectURL(imageSourceUrl);
      }
    };
    // if imageSourceUrl is in dependencies, there will be an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, id, extension, auth0]);

  return imageSourceUrl;
};
