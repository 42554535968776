import { ExpandMore } from '@mui/icons-material';
import {
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Accordion as MuiAccordion,
  Typography,
} from '@mui/material';
import { useState, type Dispatch, type ReactNode, type SetStateAction } from 'react';

export type AccordionControlProps = {
  collapsable?: boolean;
  expanded?: boolean;
  setExpanded?: Dispatch<SetStateAction<boolean>>;
};

export type AccordionProps = AccordionControlProps & {
  children?: ReactNode;
  title?: ReactNode;
  preTitle?: ReactNode;
  postTitle?: ReactNode;
  hasBorder?: boolean;
  hasBorderSummary?: boolean;
  hasPadding?: boolean;
  disableGutters?: boolean;
};

export const Accordion = ({
  collapsable = true,
  expanded,
  setExpanded,
  children,
  title,
  preTitle,
  postTitle,
  hasBorder = false,
  hasBorderSummary = true,
  hasPadding = false,
  disableGutters = false,
}: AccordionProps) => {
  const isExpanded = expanded !== undefined ? !collapsable || expanded : undefined;

  return (
    <MuiAccordion
      defaultExpanded={collapsable === false}
      disableGutters={disableGutters}
      elevation={0}
      expanded={isExpanded}
      onChange={setExpanded ? (_, isExpanded: boolean) => setExpanded(isExpanded) : undefined}
      square={false}
      sx={(theme) => ({
        border: hasBorder ? `1px solid ${theme.palette.divider}` : 'none',
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&::before': {
          display: 'none',
        },
      })}
    >
      <AccordionSummary
        expandIcon={collapsable && <ExpandMore />}
        sx={(theme) => ({
          borderBottom: isExpanded && children && hasBorderSummary ? '1px solid' : 'none',
          borderColor: theme.palette.divider,
          pointerEvents: collapsable ? 'auto' : 'none',
          '& .MuiAccordionSummary-content': {
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: '8px',
          },
        })}
      >
        {preTitle}
        {title && <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{title}</Typography>}
        {postTitle}
      </AccordionSummary>
      {children && (
        <AccordionDetails sx={{ padding: !hasPadding ? '0' : undefined }}>
          {children}
        </AccordionDetails>
      )}
    </MuiAccordion>
  );
};

export const AccordionWithCheckbox = ({
  checked,
  onCheck,
  title,
  ...accordionProps
}: AccordionProps & {
  checked: boolean;
  onCheck: (checked: boolean) => void;
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      hasBorder
      hasBorderSummary={false}
      hasPadding
      {...accordionProps}
      preTitle={
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onClick={(event) => {
                event.stopPropagation();
                const isChecked = !checked;

                onCheck(isChecked);
                setExpanded(isChecked);
              }}
            />
          }
          label={title}
          onClick={(event) => {
            event.stopPropagation();
          }}
          sx={{ pointerEvents: 'auto' }}
        />
      }
      setExpanded={setExpanded}
    />
  );
};
