import type { ExpressionSpecification } from '@maplibre/maplibre-gl-style-spec';
import type { Expression } from 'mapbox-gl';

import type { FilterValues, TrassFilter } from '@hooks/useFilters';
import { layingTypeColors, surfaceTypeColors, usageTypeColors } from '@utils/segmentMapping';

export const isSelectedExpression: ExpressionSpecification = [
  'boolean',
  ['feature-state', 'selected'],
  false,
];

export const isHoveredExpression: ExpressionSpecification = [
  'boolean',
  ['feature-state', 'hover'],
  false,
];

export const projectCloseupThreshold = 12;

export const projectOutlineWidthExpression: Expression = [
  'step',
  ['zoom'],
  0,
  projectCloseupThreshold - 0.5,
  4,
];

export const filterTrajectoryExpression = (filterValues: FilterValues): Expression => {
  const beginDate = ['slice', ['get', 'begin_date'], 0, 10];
  const scanDevice = ['get', 'scan_device_name'];
  const projectId = ['get', 'project_id'];
  const surfaceClassification = ['get', 'surface_classification_most_probable_label'];

  return [
    'all',
    ...(filterValues.dateRange.from?.isValid
      ? [['>=', beginDate, filterValues.dateRange.from.toISODate()]]
      : []),
    ...(filterValues.dateRange.to?.isValid
      ? [['<=', beginDate, filterValues.dateRange.to.toISODate()]]
      : []),
    ...(filterValues.scanDevices.length
      ? [['!', ['in', scanDevice, ['literal', filterValues.scanDevices]]]]
      : []),
    ...(filterValues.projectList.length
      ? [['in', projectId, ['literal', filterValues.projectList]]]
      : []),
    ...(filterValues.surfaceClassification.length
      ? [['!', ['in', surfaceClassification, ['literal', filterValues.surfaceClassification]]]]
      : []),
    ...(filterValues.surfaceClassification.length &&
    filterValues.surfaceClassification.includes('undefined')
      ? [['has', 'surface_classification_most_probable_label']]
      : []),
  ];
};

export const filterPrelabelExpression = (filterValues: FilterValues): Expression => {
  const beginDate = ['slice', ['get', 'begin_date'], 0, 10];
  const scanDevice = ['get', 'scan_device_name'];
  const projectId = ['get', 'project_id'];

  return [
    'all',
    ...(filterValues.dateRange.from?.isValid
      ? [['>=', beginDate, filterValues.dateRange.from.toISODate()]]
      : []),
    ...(filterValues.dateRange.to?.isValid
      ? [['<=', beginDate, filterValues.dateRange.to.toISODate()]]
      : []),
    ...(filterValues.scanDevices.length
      ? [['!', ['in', scanDevice, ['literal', filterValues.scanDevices]]]]
      : []),
    ...(filterValues.projectList.length
      ? [['in', projectId, ['literal', filterValues.projectList]]]
      : []),
  ];
};

export const filterProjectsExpression = (filterValues: FilterValues): Expression => {
  const project = ['get', 'id'];

  return [
    'all',
    ...(filterValues.projectList.length
      ? [['in', project, ['literal', filterValues.projectList]]]
      : []),
  ];
};

export const filterPhotoExpression = (filterValues: FilterValues): Expression => {
  const date = ['slice', ['get', 'date'], 0, 10];
  const scanDevice = ['get', 'scan_device_name'];
  const category = ['get', 'category_name'];
  const projectId = ['get', 'project_id'];

  return [
    'all',
    ...(filterValues.dateRange.from?.isValid
      ? [['>=', date, filterValues.dateRange.from.toISODate()]]
      : []),
    ...(filterValues.dateRange.to?.isValid
      ? [['<=', date, filterValues.dateRange.to.toISODate()]]
      : []),
    ...(filterValues.scanDevices.length
      ? [['!', ['in', scanDevice, ['literal', filterValues.scanDevices]]]]
      : []),
    ...(filterValues.photoCategories.length
      ? [['!', ['in', category, ['literal', filterValues.photoCategories]]]]
      : []),
    ...(filterValues.projectList.length
      ? [['in', projectId, ['literal', filterValues.projectList]]]
      : []),
  ];
};

export const filterTrassSegmentExpression = (filterValues: FilterValues): Expression => {
  const date = ['slice', ['get', 'scan_date'], 0, 10];
  const scanDevice = ['get', 'scan_device'];
  const projectId = ['get', 'project_id'];
  const activeTrassFilter = [
    'get',
    filterValues.activeTrassFilter === 'USAGE'
      ? 'usage_type'
      : filterValues.activeTrassFilter === 'LAYING'
        ? 'laying_type'
        : 'surface_type',
  ];
  const activeTrassFilterValue =
    filterValues.activeTrassFilter === 'USAGE'
      ? filterValues.usageTypes
      : filterValues.activeTrassFilter === 'LAYING'
        ? filterValues.layingTypes
        : filterValues.surfaceTypes;

  return [
    'all',
    filterValues.showTrasses,
    ...(filterValues.dateRange.from?.isValid
      ? [['>=', date, filterValues.dateRange.from.toISODate()]]
      : []),
    ...(filterValues.dateRange.to?.isValid
      ? [['<=', date, filterValues.dateRange.to.toISODate()]]
      : []),
    ...(filterValues.scanDevices.length
      ? [['!', ['in', scanDevice, ['literal', filterValues.scanDevices]]]]
      : []),
    ...(filterValues.projectList.length
      ? [['in', projectId, ['literal', filterValues.projectList]]]
      : []),
    ...(activeTrassFilterValue.length
      ? [['!', ['in', activeTrassFilter, ['literal', activeTrassFilterValue]]]]
      : []),
  ];
};

export const filterTrassSegmentBillingExpression = (filterValues: FilterValues): Expression => {
  const date = ['slice', ['get', 'scan_date'], 0, 10];
  const scanDevice = ['get', 'scan_device'];
  const projectId = ['get', 'project_id'];

  return [
    'all',
    ...(filterValues.dateRange?.from?.isValid
      ? [['>=', date, filterValues.dateRange.from.toISODate()]]
      : []),
    ...(filterValues.dateRange?.to?.isValid
      ? [['<=', date, filterValues.dateRange.to.toISODate()]]
      : []),
    ...(filterValues.scanDevices?.length
      ? [['!', ['in', scanDevice, ['literal', filterValues.scanDevices]]]]
      : []),
    ...(filterValues.projectList.length
      ? [['in', projectId, ['literal', filterValues.projectList]]]
      : []),
    ...(filterValues.usageTypes?.length
      ? [['!', ['in', ['get', 'usage_type'], ['literal', filterValues.usageTypes]]]]
      : []),
    ...(filterValues.layingTypes?.length
      ? [['!', ['in', ['get', 'laying_type'], ['literal', filterValues.layingTypes]]]]
      : []),
    ...(filterValues.surfaceTypes?.length
      ? [['!', ['in', ['get', 'surface_type'], ['literal', filterValues.surfaceTypes]]]]
      : []),
    ...(filterValues.width.min !== null && filterValues.width.min > 0
      ? [isAboveOrBelow(filterValues.width.min / 100, '<=', 'width')]
      : []),
    ...(filterValues.width.max !== null && filterValues.width.max > 0
      ? [isAboveOrBelow(filterValues.width.max / 100, '>', 'width')]
      : []),
    ...(filterValues.depth.min !== null && filterValues.depth.min > 0
      ? [isAboveOrBelow(filterValues.depth.min / 100, '<=', 'depth')]
      : []),
    ...(filterValues.depth.max !== null && filterValues.depth.max > 0
      ? [isAboveOrBelow(filterValues.depth.max / 100, '>', 'depth')]
      : []),
  ];
};

// returns true when `x in [min, max[`
export const isAboveOrBelow = (
  threshold: number,
  comparison: '<=' | '>',
  metric: 'width' | 'depth',
): Expression => [comparison, threshold, ['to-number', ['get', metric], 0]];
export const isBelowDepth = (depth: number): Expression => [
  '<',
  ['max', ['to-number', ['get', 'depth']], 0],
  depth,
];

export const isBelowLowestDepth = (depth: number): Expression => [
  'all',
  ['==', ['typeof', ['feature-state', 'lowestDepth']], 'number'],
  ['<', ['max', ['feature-state', 'lowestDepth'], 0], depth],
];

export const isScanExpression = (
  scanId: string | undefined,
  elementType: string | undefined,
  elementTypeToMatch: string,
): Expression => [
  'all',
  ['==', ['to-string', ['get', 'scan_id']], scanId ?? ''],
  ['==', elementType ?? '', elementTypeToMatch],
];

export const isTrassExpression = (fid: string | undefined): Expression => [
  '==',
  ['to-string', ['get', 'fid']],
  fid ?? '',
];

export const isFeatureExpression = (id?: string): Expression => [
  '==',
  ['to-string', ['get', 'id']],
  id ?? '',
];

const trassColorMapping = {
  LAYING: layingTypeColors,
  USAGE: usageTypeColors,
  SURFACE: surfaceTypeColors,
};

export const trassUsageColorExpression = (type: TrassFilter): Expression => [
  'to-color',
  [
    'case',
    ['has', `${type.toLowerCase()}_type`],
    ['get', ['get', `${type.toLowerCase()}_type`], ['literal', trassColorMapping[type]]],
    'grey',
  ],
];

export const hasOneOfIDsExpression = (fids: string[]): Expression => [
  'in',
  ['get', 'fid'],
  ['literal', fids],
];
