import type { LngLat, MapboxGeoJSONFeature } from 'mapbox-gl';
import { createContext, type ReactNode, useState } from 'react';

export type FeatureSelection = {
  selectedFeatures: MapboxGeoJSONFeature[] | null;
  location: LngLat | null;
  isInitialSelection?: boolean;
};

export type MapContextProps = {
  featureSelection: FeatureSelection;
  setFeatureSelection: React.Dispatch<React.SetStateAction<FeatureSelection>>;
};

export const MapContext = createContext<MapContextProps>({
  featureSelection: {
    selectedFeatures: null,
    location: null,
  },
  setFeatureSelection: () => {},
});

export const MapContextProvider = ({ children }: { children: ReactNode }) => {
  const [featureSelection, setFeatureSelection] = useState<FeatureSelection>({
    selectedFeatures: null,
    location: null,
  });

  return (
    <MapContext.Provider value={{ featureSelection, setFeatureSelection }}>
      {children}
    </MapContext.Provider>
  );
};
