import { type Layer } from 'mapbox-gl';
import { type ReactElement, useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';

import { type VectorLayerProps } from '@components/MapSource/VectorLayerProps';
import { useMapEvent } from '@hooks/useMapEvent';
import { isEqual } from '@utils/core';

const layerHasSource = (props: VectorLayerProps, layer: Layer) =>
  props.source === layer.source && props.sourceLayer === layer['source-layer'];

type VisibleSource = {
  topLayer: string;
  bottomLayer: string;
} & VectorLayerProps;

/*
 * Layers in react-mapbox-gl are rendered on top of all existing layers, when mounted.
 * They don't respect DOM order, which means, we need to provide a way to order them.
 * To determine a layer's order, you need to specify a `beforeId` property.
 * It says which layer comes before another. Then Mapbox can draw layers in a desired way.
 *
 * This component does that. The child components are our custom components with specified sources.
 * We can then translate the components' order to the layers' order, by specifying a `beforeId`.
 * Step 1.: get all visible layers
 * Step 2: which ones reflect our custom sources?
 * Step 3: determine the top and bottom layer of each source
 * Step 4: order them using `beforeId` and `moveLayer`
 *
 * Other People face the same problem: https://github.com/visgl/react-map-gl/issues/939
 * `beforeId` docs: https://visgl.github.io/react-map-gl/docs/api-reference/layer#beforeid
 * */
export const LayerOrder = ({ children }: { children: ReactElement<VectorLayerProps>[] }) => {
  const props = children.map((value) => value.props);
  const { current: map } = useMap();
  const [visibleSources, setVisibleSources] = useState<VisibleSource[]>([]);

  const callback = useCallback(() => {
    if (!map) return;

    // get all layers
    const layers = map.getStyle().layers.filter((layer) => layer.type !== 'custom') as Layer[];

    // get all related layers of our sources
    const candidate = props
      .map((value) => ({
        ...value,
        bottomLayer: layers.find((l) => layerHasSource(value, l))?.id,
        topLayer: layers.findLast((l) => layerHasSource(value, l))?.id,
      }))
      .filter((value) => value.bottomLayer && value.topLayer) as VisibleSource[];

    // update visible sources if needed
    if (!isEqual(visibleSources, candidate)) {
      setVisibleSources(candidate);
    }
  }, [map, props, visibleSources]);

  useMapEvent('styledata', callback);

  // use top and bottom layer of our sources to sort them, in the order their components are sorted
  useEffect(() => {
    try {
      map &&
        visibleSources.forEach((value, index) =>
          map.moveLayer(value.topLayer, visibleSources?.[index - 1]?.bottomLayer),
        );
    } catch (error) {
      console.error(error);
    }
  }, [visibleSources, map]);

  return <>{children}</>;
};
