import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

type Props = {
  photosLength: number;
  projectId: string;
};

export const DownloadButton = ({ photosLength, projectId }: Props) => {
  const [isZipPending, setIsZipPending] = useState<boolean>(false);
  const [isRecentZipAvailable, setIsRecentZipAvailable] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    setIsZipPending(false);
    setIsRecentZipAvailable(false);
  }, [projectId]);

  return (
    <Box component="div">
      <Paper elevation={2} sx={{ padding: theme.spacing(2) }}>
        <Stack direction="row" spacing={2}>
          <Typography
            paragraph
            sx={{ fontWeight: 'bold', paddingTop: theme.spacing(1.4) }}
            variant="subtitle1"
          >
            {t('components.photoDownload.download.title')}
          </Typography>
          {isRecentZipAvailable ? (
            <Stack component="div" direction="column" spacing={1}>
              <Button
                color="primary"
                size="medium"
                sx={{
                  height: 48,
                }}
                variant="contained"
              >
                <Typography noWrap>
                  {t('components.photoDownload.download.downloadCta')} (6)
                </Typography>
              </Button>
              <Typography noWrap variant="body2">
                {t('components.photoDownload.download.created')} 24.08.2024
                <br />
                {t('components.photoDownload.download.available')} 31.08.2024
              </Typography>
            </Stack>
          ) : (
            <Stack component="div" direction="column" spacing={1}>
              <Typography
                p={2}
                sx={{
                  width: 200,
                  border: '2px dashed',
                  borderColor: theme.palette.grey[400],
                  color: theme.palette.grey[400],
                  borderRadius: theme.spacing(1),
                  textAlign: 'center',
                }}
                variant="body2"
              >
                Noch keine Zip zum Herunterladen erstellt
              </Typography>
            </Stack>
          )}
          <Box component="div">
            <Button
              color="primary"
              onClick={() => {
                // TODO - cleanup mocks
                setIsZipPending(true);
                setIsRecentZipAvailable(true);
              }}
              size="medium"
              sx={{
                height: 48,
              }}
              variant="contained"
            >
              {!isZipPending ? (
                <>
                  <Typography noWrap>
                    {t('components.photoDownload.download.produceCta')} ({photosLength})
                  </Typography>
                </>
              ) : (
                <Stack alignItems="center" direction="row" spacing={2}>
                  <CircularProgress color="warning" />
                  <Typography noWrap>
                    {t('components.photoDownload.download.pendingLabel')}
                  </Typography>
                </Stack>
              )}
            </Button>
            {isZipPending && (
              <Box component="div">
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <Typography noWrap>
                      {t('components.photoDownload.download.automatedLabel')}
                    </Typography>
                  }
                />
              </Box>
            )}
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};
