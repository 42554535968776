import { Chip } from '@mui/material';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckBoxGroupReversed } from '@components/FilterBox/fields/CheckBoxGroup';
import {
  useLayingTypeReadable,
  useSurfaceTypeReadable,
  useUsageTypeReadable,
} from '@components/SegmentInfoCard';
import { type TrassFilter, TrassFilterEnum, useFilters } from '@hooks/useFilters';
import { useMapFeatures } from '@hooks/useMapFeatures';
import { getNumberSelected, isDefined } from '@utils/core';
import type { LayingType, SurfaceType, UsageType } from '@utils/segmentMapping';

import { AccordionWithCheckbox } from '../Accordion';
import { Tabs } from '../Tabs';

export const TrassesFilters = ({ title }: { title: ReactNode }) => {
  const { t } = useTranslation();
  const { usageTypeReadable } = useUsageTypeReadable();
  const { layingTypeReadable } = useLayingTypeReadable();
  const { surfaceTypeReadable } = useSurfaceTypeReadable();

  const {
    showTrasses,
    setShowTrasses,
    usageTypes,
    setUsageTypes,
    layingTypes,
    setLayingTypes,
    surfaceTypes,
    setSurfaceTypes,
    activeTrassFilter,
    setActiveTrassFilter,
  } = useFilters();

  const features = useMapFeatures({
    source: 'trass-source',
    sourceLayer: 'trass_segmented',
    filter: ['to-boolean', showTrasses],
  });

  const availableUsageTypes = [
    ...new Set([
      ...features.map((f) => f.properties?.usage_type).filter(isDefined<UsageType>),
      ...usageTypes,
    ]),
  ];

  const availableLayingTypes = [
    ...new Set([
      ...features.map((f) => f.properties?.laying_type).filter(isDefined<LayingType>),
      ...layingTypes,
    ]),
  ];

  const availableSurfaceTypes = [
    ...new Set([
      ...features.map((f) => f.properties?.surface_type).filter(isDefined<SurfaceType>),
      ...surfaceTypes,
    ]),
  ];

  const currentFilterTypes =
    activeTrassFilter === 'SURFACE'
      ? surfaceTypes
      : activeTrassFilter === 'USAGE'
        ? usageTypes
        : layingTypes;

  const availableFilterTypes =
    activeTrassFilter === 'SURFACE'
      ? availableSurfaceTypes
      : activeTrassFilter === 'USAGE'
        ? availableUsageTypes
        : availableLayingTypes;

  const currentFilterNumber = getNumberSelected(availableFilterTypes, currentFilterTypes);

  return (
    <AccordionWithCheckbox
      checked={showTrasses}
      hasPadding={false}
      onCheck={setShowTrasses}
      postTitle={
        !showTrasses ? null : (
          <Chip
            color="primary"
            label={`${!currentFilterTypes.length ? t('components.filterBox.trassesFilters.activeInfo.all') : currentFilterNumber} ${t('components.filterBox.trassesFilters.activeInfo.active')}`}
          />
        )
      }
      title={title}
    >
      <Tabs
        index={TrassFilterEnum[activeTrassFilter]}
        isFullHeight={false}
        items={[
          {
            label: t('components.filterBox.trassesFilters.tabs.usage'),
            children: (
              <CheckBoxGroupReversed
                availableItems={availableUsageTypes}
                disabled={!showTrasses}
                items={usageTypes}
                setItems={setUsageTypes}
                transformLabel={(label) => usageTypeReadable[label as UsageType] ?? label}
              />
            ),
          },
          {
            label: t('components.filterBox.trassesFilters.tabs.layingType'),
            children: (
              <CheckBoxGroupReversed
                availableItems={availableLayingTypes}
                disabled={!showTrasses}
                items={layingTypes}
                setItems={setLayingTypes}
                transformLabel={(label) => layingTypeReadable[label as LayingType] ?? label}
              />
            ),
          },
          {
            label: t('components.filterBox.trassesFilters.tabs.surface'),
            children: (
              <CheckBoxGroupReversed
                availableItems={availableSurfaceTypes}
                disabled={!showTrasses}
                items={surfaceTypes}
                setItems={setSurfaceTypes}
                transformLabel={(label) => surfaceTypeReadable[label as SurfaceType] ?? label}
              />
            ),
          },
        ]}
        onChange={(selectedIndex) =>
          setActiveTrassFilter(TrassFilterEnum[selectedIndex] as TrassFilter)
        }
      />
    </AccordionWithCheckbox>
  );
};
